import React, { useState, useEffect, useRef, useContext } from "react";
import { Container, Row, Col,Label, NavLink  } from "reactstrap";
import ReactToPrint from "react-to-print-advanced";
import CloseIcon from '@mui/icons-material/Close';
import { NavLink as RRNavLink, Outlet } from "react-router-dom";
import StoreContext from "./../Store/Context";
import { CLOSE_IMAGE } from "../Image";
import { validate } from 'react-email-validator';
import {CotizacionModal } from "./../Modal";
import {IconButton } from "@mui/material";
import { FIND_DESC } from "./../Util";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {ComponentToPrintsCotizacion} from "../../Inventario/ComponentToPrintss";
import {STATES_COT_COTIZACION} from "../States";
import {DATATABLE_COTIZACION} from "../Datatables"
import {  get_gr_garantia,delete_cotd_cotizacion_detail,post_cotd_cotizacion_detail_row,put_cot_cotizacion_encabezado,get_compania,
  put_cot_cotizacion_sts,get_articulo_by_code,get_cli_cliente, get_list_items, post_cli_cliente, put_cli_cliente,
  put_cotd_cotizacion_detalle_row, get_cotd_cotizacion_detail, post_cot_cotizacion, get_all_cotizacion } from "../Services";

export const Cotizacion = () => {
  var componentRef = useRef();
  const { usr_id,usr_precio } = useContext(StoreContext);
  const [dataGarantia, setDataGarantia] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPrinting, setPrintI] = useState(false);
  const [isTabAddClientes, setTabAddClientes] = useState(false);
  const [isSaveI, setSaveI] = useState(false);
  const [error_upc, setError_upc] = useState("");
  const [dataCliente, setDataCliente] = useState([]);
  const [dataCotizacion, setDataCotizacion] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [OBJCotizacionDetalle, setOBJCotizacionDetalle] = useState([]);
  const [formCompania, setFormCompania] = useState(STATES_COT_COTIZACION({staneName:"formCompania"}));
  const [formCotCotizacion, setFormCotCotizacion] = useState(STATES_COT_COTIZACION({staneName:"formCotCotizacion"}));
  const [formCotCotizacionDetalle, setFormCotCotizacionDetalle] = useState(STATES_COT_COTIZACION({staneName:"formCotCotizacionDetalle"}));
  const [formClientes, setFormClientes] = useState(STATES_COT_COTIZACION({staneName:"formClientes"}));
  const [formDropDownCliente, setFormDropDownCliente] = useState(STATES_COT_COTIZACION({staneName:"formDropDownCliente"}));
  const [formPrecio, setFormPrecio] = useState(STATES_COT_COTIZACION({staneName:"formPrecio"}));

  async function fetchCompania() {
    var response = await get_compania();
    setFormCompania({
      bod_suc: response[0].bod_suc,
      com_nombre: response[0].com_nombre,
      com_ruc: response[0].com_ruc,
      com_dv: response[0].com_dv,
      com_direccion: response[0].com_direccion,
      com_telefono: response[0].com_telefono,
      com_telefono2: response[0].com_telefono2,
      com_email: response[0].com_email,
      com_fax: response[0].com_fax,
      com_web: response[0].com_web,
      com_info_interes:response[0].com_info_interes,
      com_cotizacion_info:response[0].com_cotizacion_info,
      com_cot_image:response[0].com_cot_image
    });
  }

  async function fetch_cot_cotizacion() {
    var response = await get_all_cotizacion();
    setDataCotizacion(response);
  }
  
  async function post_cotd_cotizacion_detail({body}) {
    var response = post_cotd_cotizacion_detail_row(body);
    response.then((data) => {
      setError("");
      if(data===true)
        fetchCotdDetalle(body.cotd_identificador)
      else
        setError("ERROR "+data);
    });
  }

  async function put_cotd_cotizacion_detail_({body}) {
    var result = put_cotd_cotizacion_detalle_row(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchCotdDetalle(body.cotd_identificador)
      else
        setError("ERROR "+data);
    });
  }

  async function fetchGarantia() {
    var response = await get_gr_garantia();
    setDataGarantia(response);
  }

  async function fetchCliente() {
    var response = await get_cli_cliente();
    setDataCliente(response);
  }

  async function fetch_list_items() {
    var response = await get_list_items();
    setDataItems(response);
  }

  async function fetchArticuloByUpc(art_upc) {
    var response = await get_articulo_by_code(art_upc);
    if (response[0] !== undefined) {
      setFormPrecio({
        art_precio:response[0].art_precio,
        art_precio2:response[0].art_precio2,
        art_precio3:response[0].art_precio3,
      })
      setError_upc("");
      setFormCotCotizacionDetalle({
        ...formCotCotizacionDetalle,
        cotd_articulo: response[0].art_id,
        art_descripcion: response[0].art_descripcion,
        art_upc: response[0].art_upc,
        cotd_precio: response[0].art_precio,
        art_itbm_cod_v: response[0].art_itbm_cod_v,
        art_itbm_venta:response[0].art_itbm_venta,
        cotd_cantidad:"1",
        art_imgName:response[0].art_imgName,
        art_img:response[0].art_img,
        art_referencia:response[0].art_referencia,
      });
    } else {
      setError_upc("Articulo no existe");
    }
  }

  useEffect(() => {
    fetchCliente();
    fetch_list_items();
    fetch_cot_cotizacion();
    fetchCompania();
    fetchGarantia();
  }, []);

  const onChangeDropdown = (selectedOptions) => {
    setFormDropDownCliente({
      ...formDropDownCliente,
      cli_id: selectedOptions.value,
      cli_nombre: selectedOptions.label,
      cli_ruc: selectedOptions.ruc,
      cli_email: selectedOptions.email,
      cli_dv: selectedOptions.dv
    });
    setError("");
  };

  const selectCliente = (row) => {
    setFormDropDownCliente({
      ...formDropDownCliente,
      cli_id: row.original.cli_id,
      cli_nombre: row.original.cli_nombre,
      cli_ruc: row.original.cli_ruc,
      cli_email: row.original.cli_email,
      cli_dv: row.original.cli_dv
    });
    setError("");
  };

  const onClickDeleteCotizacion_sts = ({ cot_id, cot_status }) => {
    var body = {
      cot_id,
      cot_status,
    };

    var result = put_cot_cotizacion_sts(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetch_cot_cotizacion();
      else
        setError("ERROR "+data);
    });
  };
  
  function onChangeCot_CotizacionDetalle(event) {
    const { value, name } = event.target;
    setFormCotCotizacionDetalle({
      ...formCotCotizacionDetalle,
      art_descripcion: name === "art_upc" ? "" : formCotCotizacionDetalle.art_descripcion,
      cotd_precio: name === "art_upc" ? "" : formCotCotizacionDetalle.cotd_precio,
      cotd_cantidad: name === "art_upc" ? "" : formCotCotizacionDetalle.cotd_cantidad,
      art_referencia: name === "art_upc" ? "" : formCotCotizacionDetalle.art_referencia,
      [name]: value,
    });

    setError("");
    setError_upc("");
  }

  const deleteRow = (row) => {
    var value = OBJCotizacionDetalle.filter((data, i) => {
      if (i !== row.index) return data;
    });
    setOBJCotizacionDetalle(value);
    if(basicModalEdit){
      var body ={
        cotd_id:row.original.cotd_id
      }
      delete_cotd_cotizacion_detail(body)
    }
  };

  const onBlurF = (event, row) => {
    var data = OBJCotizacionDetalle.filter((datos, i) => {
      if (i !== row.index) {
        return datos;
      }
    });
    setOBJCotizacionDetalle((OBJCotizacionDetalle) => [
      ...data,
      ...[
        {
          art_descripcion: row.original.art_descripcion,
          cotd_cantidad: event.target.value,
          cotd_itbms: row.original.cotd_itbms,
          cotd_precio: row.original.cotd_precio,
          cotd_total: row.original.cotd_total,
          cotd_articulo: row.original.cotd_articulo,
        },
      ],
    ]);
  };

  function handleKeyPress(event) {
    const { value, name } = event.target;
    if (event.key === "Enter" && name === "art_upc") {
      fetchArticuloByUpc(value);
    }

    if (event.key === "Enter" && name === "cotd_cantidad") {
      var flag = false;
      var itbms=0;
      var total =0;
      var cantidad =0;
      var cotd_id ="";
      
      var objItems = OBJCotizacionDetalle.map((data, i) => {
        if (data.cotd_articulo === formCotCotizacionDetalle.cotd_articulo && data.art_descripcion === formCotCotizacionDetalle.art_descripcion) {
          cantidad = (Number(data.cotd_cantidad) + Number(formCotCotizacionDetalle.cotd_cantidad)).toFixed(2);
          itbms = ((Number(cantidad)*Number(data.cotd_precio))*Number(data.art_itbm_venta)).toFixed(4);
          total = ((Number(cantidad)*Number(data.cotd_precio)) + Number(itbms)).toFixed(4);
          flag = true;
          data.cotd_cantidad = Number(cantidad).toFixed(2);
          data.cotd_itbms = itbms;
          data.cotd_total = ((Number(cantidad)*Number(formCotCotizacionDetalle.cotd_precio))+(Number(itbms))).toFixed(4);
          cotd_id = data.cotd_id;
        }
        return data;
      });
      if (!flag) {
        itbms = ((Number(formCotCotizacionDetalle.cotd_cantidad)*Number(formCotCotizacionDetalle.cotd_precio))*Number(formCotCotizacionDetalle.art_itbm_venta)).toFixed(4);
        total = ((Number(formCotCotizacionDetalle.cotd_cantidad)*Number(formCotCotizacionDetalle.cotd_precio))+(Number(itbms))).toFixed(4);
        setOBJCotizacionDetalle([...OBJCotizacionDetalle, ...[{...formCotCotizacionDetalle,
          cotd_itbms:itbms,
          cotd_total:total,
        }]]);
        if(basicModalEdit){
          var body ={
            cotd_identificador: formCotCotizacion.cot_identificador,
            cotd_articulo: formCotCotizacionDetalle.cotd_articulo,
            cotd_cantidad: formCotCotizacionDetalle.cotd_cantidad,
            cotd_descuento: formCotCotizacionDetalle.cotd_descuento,
            cotd_descuento_number: formCotCotizacionDetalle.cotd_descuento_number,
            cotd_precio: formCotCotizacionDetalle.cotd_precio,
            cotd_itbms: itbms,
            cotd_total: total,
            cotd_art_descripcion:formCotCotizacionDetalle.art_descripcion,
          }
          post_cotd_cotizacion_detail({body})
        }

      } else {
        setOBJCotizacionDetalle(objItems);
        if(basicModalEdit){
          var body ={
            cotd_id:cotd_id,
            cotd_identificador: formCotCotizacion.cot_identificador,
            cotd_articulo: formCotCotizacionDetalle.cotd_articulo,
            cotd_cantidad: cantidad,
            cotd_descuento: formCotCotizacionDetalle.cotd_descuento,
            cotd_descuento_number: formCotCotizacionDetalle.cotd_descuento_number,
            cotd_precio: formCotCotizacionDetalle.cotd_precio,
            cotd_itbms: itbms,
            cotd_total: total,
            cotd_art_descripcion:formCotCotizacionDetalle.art_descripcion,
          }
          put_cotd_cotizacion_detail_({body})
        }
      }
      setFormCotCotizacionDetalle(STATES_COT_COTIZACION({staneName:"formCotCotizacionDetalle"}));
    }
    setError("");
  }

  const onClickNuevaCotizacion = () => {
    setBasicModalEdit(false)
    setBasicModal(true);
    setOBJCotizacionDetalle([]);
    setFormDropDownCliente(STATES_COT_COTIZACION({staneName:"formDropDownCliente"}));
    setFormCotCotizacionDetalle(STATES_COT_COTIZACION({staneName:"formCotCotizacionDetalle"}));
    setFormCotCotizacion(STATES_COT_COTIZACION({staneName:"formCotCotizacion"}))
  };


  const onClickSaveCotizacion = () => {
    setLoading(true);
    if (OBJCotizacionDetalle[0] !== undefined) {
      var dataCOT_detail = OBJCotizacionDetalle.map((val, i) => {
        return {
          cotd_identificador: formCotCotizacion.cot_identificador,
          cotd_articulo: val.cotd_articulo,
          cotd_art_descripcion:val.art_descripcion,
          cotd_precio: val.cotd_precio,
          cotd_cantidad: val.cotd_cantidad,
          cotd_itbms: Number(val.cotd_itbms).toFixed(4),
          cotd_descuento: val.cotd_descuento,
          cotd_total: Number(val.cotd_total).toFixed(4),
          cotd_descuento_number: val.cotd_descuento_number,
        };
      });

      var body = {
        formCotCotizacion: {
          cot_identificador: formCotCotizacion.cot_identificador,
          cot_cliente: formDropDownCliente.cli_id,
          cot_fecha: formCotCotizacion.cot_fecha,
          cot_vendedor: usr_id,
          cot_descripcion: formCotCotizacion.cot_descripcion,
          cot_garantia:formCotCotizacion.cot_garantia,
          cot_garantia_name:formCotCotizacion.cot_garantia_name
        },
        dataCotizacionDetalle: dataCOT_detail,
      };


      var result = post_cot_cotizacion(body);
      result.then((data) => {
        setError("");
        if(data===true)
        {
          setSaveI(false);
          setBasicModal(false);
          fetch_cot_cotizacion();
          setLoading(false)
        }
        else
          setError("ERROR "+data);
      });

    } else {
      setError("Campos vacios");
    }
  };

  const toggleSaveEncabezado = () => {
    var body = {
      cot_garantia:formCotCotizacion.cot_garantia,
      cot_garantia_name:formCotCotizacion.cot_garantia_name,
      cot_fecha:formCotCotizacion.cot_fecha,
      cot_id:formCotCotizacion.cot_id,
      cot_descripcion:formCotCotizacion.cot_descripcion
    };
    console.log(body)
    put_cot_cotizacion_encabezado(body)
  };
  
  function onChangeCliente(event) {
    const { value, name } = event.target;
    setFormClientes({
      ...formClientes,
      [name]: value,
    });
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormCotCotizacion({
      ...formCotCotizacion,
      [name]: value,
    });
    setError("");
  }

  const onClickSaveCliente = () => {
    setLoading(true)
    var body = {
      cli_nombre: formClientes.cli_nombre,
      cli_direccion: formClientes.cli_direccion,
      cli_telefono: formClientes.cli_telefono,
      cli_telefono2: formClientes.cli_telefono2,
      cli_ruc: formClientes.cli_ruc,
      cli_dv: formClientes.cli_dv,
      cli_email: formClientes.cli_email,
      cli_limitc: formClientes.cli_limitc,
      cli_credito:formClientes.cli_credito
    };
    if (formClientes.cli_nombre.length > 0) {
      if(formClientes.cli_email.length>0){
        if(validate(formClientes.cli_email)){
          if(FIND_DESC(dataCliente,formClientes.cli_ruc,null)){
            setError("Error: Ruc ya esta registrado");
          }
          else{

            var result = post_cli_cliente(body);
            result.then((data) => {
              setError("");
              if(data===true)
              {
                fetchCliente();
                setTabAddClientes(false)
                setLoading(false)
              }
              else
                setError("ERROR "+data);
            });
          }
        }
        else{
          setError("E-Mail incorrecto");
        }
      }
      else{
        if(FIND_DESC(dataCliente,formClientes.cli_ruc,null)){
          setError("Error: Ruc ya esta registrado");
        }
        else{
          var result = post_cli_cliente(body);
          result.then((data) => {
            setError("");
            if(data===true)
            {
              fetchCliente();
              setTabAddClientes(false)
            }
            else
              setError("ERROR "+data);
          });
        }
      }

    } else {
      setError("Campos importantes estan vacios");
    }
  };

  function setFormClientesBlank(){
    setFormClientes(STATES_COT_COTIZACION({staneName:"formClientes"}))
  }
  async function fetchCotdDetalle(cot_identificador) {
    var response;
    response = await get_cotd_cotizacion_detail(cot_identificador);
    setOBJCotizacionDetalle(response);
  }

  const update_cotd_cotizacion_detalle_row = (values, cotd_identificador) => {
    var result = put_cotd_cotizacion_detalle_row(values);
    result.then((data) => {
      setError("");
      if(data===true)
      {
        fetchCotdDetalle(cotd_identificador)
      }
      else
        setError("ERROR "+data);
    });
  };

  const onClickUpdateClient = ({cli_id, cli_nombre,cli_direccion,cli_telefono2,  cli_ruc,cli_dv,cli_telefono,cli_email,cli_limitc,cli_credito}) => {
    setLoading(true)
    var body = {
      cli_id,
      cli_nombre,
      cli_direccion,
      cli_telefono,
      cli_telefono2,
      cli_ruc,
      cli_dv,
      cli_email,
      cli_limitc,
      cli_credito
    };
    if (cli_nombre.length > 0) {
      var result = put_cli_cliente(body);
      result.then((data) => {
        setError("");
        if(data===true)
        {
          fetchCliente();
          setLoading(false);
        }
        else
          setError("ERROR "+data);
      });
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchArticuloByUpcRe(art_upc) {
    var response;
    response = await get_articulo_by_code(art_upc);
    if (response[0] !== undefined) {
      setFormPrecio({
        art_precio:response[0].art_precio,
        art_precio2:response[0].art_precio2,
        art_precio3:response[0].art_precio3,
      })
      setError_upc("");
      setFormCotCotizacionDetalle({
        ...formCotCotizacionDetalle,
        cotd_articulo:response[0].art_id,
        cotd_cantidad: "1",
        art_upc: response[0].art_upc,
        art_descripcion: response[0].art_descripcion,
        cotd_precio:response[0].art_precio,
        art_itbm_cod_v:response[0].art_itbm_cod_v,
        art_itbm_venta:response[0].art_itbm_venta,
        art_imgName:response[0].art_imgName,
        art_img:response[0].art_img,
        art_referencia:response[0].art_referencia,
      });
    } else {
      setError_upc("Articulo no existe");
    }
  }
  
  var ObjClientes = [];
  dataCliente.map((item) => {
    ObjClientes.push({
      value: item.cli_id,
      label: item.cli_nombre + " " + item.cli_ruc,
      ruc: item.cli_ruc,
      email: item.cli_email,
      dv: item.cli_dv,
      objName: "cliente",
    });
  });

  var enableTab = true;
  if (formClientes.cli_id.length > 0) {
    enableTab = false;
  }

  function setPrecio(art_precio) {
    setFormCotCotizacionDetalle({
      ...formCotCotizacionDetalle,
      cotd_precio: art_precio,
    }); 
  };
  const handleOnChangeCheckBox = (event) => {
    const { value, name } = event.target;
    setFormClientes({
      ...formClientes,
      [name]:value==="0"?"1":"0"
  })
  };
  return (
    <Container className="bg-light border" fluid>
      {basicModal && (
        <CotizacionModal
          formCotizacion={formCotCotizacion}
          isOpen={basicModal}
          toggle={setBasicModal}
          onChange={onChange}
          error={error}
          error_upc={error_upc}
          basicModalEdit={basicModalEdit}
          formDropDownCliente={formDropDownCliente}
          OBJCotizacionDetalle={OBJCotizacionDetalle}
          formCotCotizacionDetalle={formCotCotizacionDetalle}
          propsDropdown={ObjClientes}
          toggleSave={onClickSaveCotizacion}
          onChangeCot_detail={onChangeCot_CotizacionDetalle}
          handleKeyPress={handleKeyPress}
          onChangeDropdown={onChangeDropdown}
          onBlurs={onBlurF}
          isSaveI={isSaveI}
          deleteRow={deleteRow}
          setOBJCotizacionDetalle={setOBJCotizacionDetalle}
          fetch_cot_cotizacion={fetch_cot_cotizacion}
          update_cotd_cotizacion_detalle_row={update_cotd_cotizacion_detalle_row}
          toggleSaveEncabezado={toggleSaveEncabezado}
          selectCliente={selectCliente}
          dataCliente={dataCliente}
          formClientes={formClientes}
          onChangeCliente={onChangeCliente}
          setFormClientesBlank={setFormClientesBlank}
          onClickSaveCliente={onClickSaveCliente}
          selectItem={fetchArticuloByUpcRe}
          dataItems={dataItems}
          isTabAddClientes={isTabAddClientes}
          setTabAddClientes = {setTabAddClientes}
          setFormClientes={setFormClientes}
          setError={setError}
          onClickUpdateClient={onClickUpdateClient}
          usr_precio={usr_precio}
          setPrecio={setPrecio}
          formPrecio={formPrecio}
          handleOnChangeCheckBox={handleOnChangeCheckBox}
          loading={loading}
          dataGarantia={dataGarantia}
          formCotCotizacion={formCotCotizacion}
          setFormCotCotizacion={setFormCotCotizacion}
        />
      )}
      <Row className="mt-2">
        <Col xs={11} className="text-center">
          <Label className="font-weight-bold-title">COTIZACIÓN</Label>
        </Col>
        <Col xs={1} className="text-end">
          <NavLink tag={RRNavLink} to="/user/home" className="font-w-bold   menu-l-c">
            <img src={CLOSE_IMAGE} className="src-image" alt="..."/>
          </NavLink>
        </Col>
        {isPrinting ? (
          <React.Fragment>
            <Col xs={10}></Col>
              <Col xs={2} >
                <IconButton className="float-right-btn"
                  onClick={() => {
                    setPrintI(false);
                    setFormCotCotizacion(STATES_COT_COTIZACION({staneName:"formCotCotizacion"}));
                    setOBJCotizacionDetalle([]);
                  }}>
                  <CloseIcon />
                </IconButton>
                <ReactToPrint
                  trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                  content={() => componentRef} />
              </Col>
              <Col xs={12} className="text-center scrol-table2">
                <ComponentToPrintsCotizacion
                  ref={el => (componentRef = el)}
                  props={OBJCotizacionDetalle}
                  dataCompania={formCompania}
                  form={formCotCotizacion}
                />
              </Col>
          </React.Fragment>
          ) :(
            <Col xs={12} className="modal-add-inventario datatable-factura">
              <DATATABLE_COTIZACION dataCotizacion={dataCotizacion}onClickDeleteCotizacion_sts={onClickDeleteCotizacion_sts} setBasicModalEdit={setBasicModalEdit} 
              setBasicModal={setBasicModal} setFormCotCotizacion={setFormCotCotizacion} fetchCotdDetalle={fetchCotdDetalle} 
              setFormDropDownCliente={setFormDropDownCliente} setPrintI={setPrintI} onClickNuevaCotizacion={onClickNuevaCotizacion} fetch_cot_cotizacion={fetch_cot_cotizacion}/>
          </Col>
          )}

      </Row>
    </Container>
  );
};
