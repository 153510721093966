import React, { useState, useEffect } from "react";
import { validate } from "react-email-validator";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "../../Componentes/www/style.css";
import moment from "moment";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { DATATABLE_CLIENTES, DATATABLE_PROVEEDORES } from "../Datatables";
import { STATES_CLI_CLIENTES } from "../States";
import { FIND_DESC } from "../Util";
import { CLOSE_IMAGE } from "../Image";
import { NavLink as RRNavLink, Outlet } from "react-router-dom";
import { ClienteModal, DeleteModal, ProveedorModal } from "../Modal";
import {
  get_cli_cliente,
  post_cli_cliente,
  put_cli_cliente,
  put_cli_cliente_sts,
  post_pro_proveedor,
  get_pro_proveedor,
  put_pro_proveedor,
  put_pro_proveedor_sts,
} from "../Services";
import { Container, Row, Col,NavItem, NavLink } from "reactstrap";
import {
  MDBIcon,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";

export const Contactos = () => {
  const [iconsActive, setIconsActive] = useState("tab1");

  const handleIconsClick = (value) => {
    if (value === iconsActive) {
      return;
    }
    setIconsActive(value);
  };

  return (
    <>
      <MDBTabs className="mb-3">
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleIconsClick("tab1")}
            active={iconsActive === "tab1"}
          >
            <MDBIcon fas icon="building" className="me-2" /> Clientes
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleIconsClick("tab2")}
            active={iconsActive === "tab2"}
          >
            <MDBIcon fas icon="people-carry" className="me-2" /> Proveedores
          </MDBTabsLink>
        </MDBTabsItem>
        <NavLink tag={RRNavLink} to="/user/home" className="font-w-bold   menu-l-c">
            <img src={CLOSE_IMAGE} className="src-image position-close" alt="..."/>
          </NavLink>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane show={iconsActive === "tab1"}>
          <Clientes />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab2"}>
          <Proveedores />
        </MDBTabsPane>
      </MDBTabsContent>
    </>
  );
};
function Clientes() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [isLoadingC, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [dataClientes, setDataClientes] = useState([]);
  const [formClientes, setFormClientes] = useState(STATES_CLI_CLIENTES("formClientes"));
  const setFormClientesBlank = () => {
    setFormClientes(STATES_CLI_CLIENTES("formClientes"));
  };

  const onClickUpdateClient = () => {
    setLoading(true)
    var body = {
      cli_id: formClientes.cli_id,
      cli_nombre: formClientes.cli_nombre,
      cli_direccion: formClientes.cli_direccion,
      cli_telefono: formClientes.cli_telefono,
      cli_telefono2: formClientes.cli_telefono2,
      cli_ruc: formClientes.cli_ruc,
      cli_dv: formClientes.cli_dv,
      cli_email: formClientes.cli_email,
      cli_limitc: formClientes.cli_limitc,
      cli_credito: formClientes.cli_credito,
    };
    if (formClientes.cli_nombre.length > 0) {
      if (formClientes.cli_email.length > 0) {
        if (validate(formClientes.cli_email)) {
          if (FIND_DESC(dataClientes, formClientes.cli_ruc, formClientes.cli_id)) {
            setError("Error: Ruc ya esta registrado");
          } else {
            var result = put_cli_cliente(body);
            result.then((data) => {
              setError("");
              if(data===true){
                setLoading(false);
                fetchCliente();
              }
              else
                setError("ERROR "+data);
            });
          }
        } else {
          setError("E-Mail incorrecto");
        }
      } else {
        if (FIND_DESC(dataClientes, formClientes.cli_ruc, formClientes.cli_id)) {
          setError("Error: Ruc ya esta registrado");
        } else {
          var result = put_cli_cliente(body);
          result.then((data) => {
            setError("");
            if(data===true)
              fetchCliente();
            else
              setError("ERROR "+data);
          });
        }
      }
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  const onClickUpdateCliente_sts = () => {
    var body = {
      cli_id: formClientes.cli_id,
      cli_sts: 0,
    };
    var result = put_cli_cliente_sts(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchCliente();
      else
        setError("ERROR "+data);
    });
  };

  const onClickSaveCliente = () => {
    var body = {
      cli_nombre: formClientes.cli_nombre,
      cli_direccion: formClientes.cli_direccion,
      cli_telefono: formClientes.cli_telefono,
      cli_telefono2: formClientes.cli_telefono2,
      cli_ruc: formClientes.cli_ruc,
      cli_dv: formClientes.cli_dv,
      cli_email: formClientes.cli_email,
      cli_limitc: formClientes.cli_limitc,
      cli_credito: formClientes.cli_credito,
    };

    if (formClientes.cli_nombre.length > 0) {
      if (formClientes.cli_email.length > 0) {
        if (validate(formClientes.cli_email)) {
          if (FIND_DESC(dataClientes, formClientes.cli_ruc, null)) {
            setError("Error: Ruc ya esta registrado");
          } else {
            var result = post_cli_cliente(body);
            result.then((data) => {
              setError("");
              if(data===true){
                setLoading(false)
                fetchCliente();
              }
              else
                setError("ERROR "+data);
            });
          }
        } else {
          setError("E-Mail incorrecto");
        }
      } else {
        if (FIND_DESC(dataClientes, formClientes.cli_ruc, null)) {
          setError("Error: Ruc ya esta registrado");
        } else {
          var result = post_cli_cliente(body);
          result.then((data) => {
            setError("");
            if(data===true)
              fetchCliente();
            else
              setError("ERROR "+data);
          });
        }
      }
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchCliente() {
    setLoading(true);
    var response;
    response = await get_cli_cliente();
    setDataClientes(response);
    setBasicModal(false);
    setBasicModalDelete(false);
    setBasicModalEdit(false);
    setLoading(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormClientes({
      ...formClientes,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchCliente();
  }, []);

  const handleOnChangeCheckBox = (event) => {
    const { value, name } = event.target;
    setFormClientes({
      ...formClientes,
      [name]: value === "0" ? "1" : "0",
    });
  };

  return (
    <Container>
      {basicModal && (
        <ClienteModal
          props={formClientes}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveCliente}
          toggleSaveEdit={onClickUpdateClient}
          onChange={onChange}
          error={error}
          setFormClientesBlank={setFormClientesBlank}
          handleOnChangeCheckBox={handleOnChangeCheckBox}
          loading={isLoadingC}
        />
      )}
      {basicModalDelete && (
        <DeleteModal
          props={
            formClientes.cli_nombre +
            " " +
            formClientes.cli_ruc +
            " " +
            formClientes.cli_dv
          }
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateCliente_sts}
          text={"Eliminar este registro?"}
          error={error}
        />
      )}
      <Row>
        <Col className="modal-add-inventario datatable-factura">
          <DATATABLE_CLIENTES
            dataClientes={dataClientes}
            setBasicModalDelete={setBasicModalDelete}
            setFormClientes={setFormClientes}
            setBasicModal={setBasicModal}
            setBasicModalEdit={setBasicModalEdit}
            setError={setError}
            fetchBodega={fetchCliente}
            isLoadingC={isLoadingC}
          />
        </Col>
      </Row>
    </Container>
  );
}
function Proveedores() {
  const csvConfigProveedor = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
    filename:
      "base_de_datos_proveedores" + moment(new Date()).format("_YYYY_MM_DD") + ".csv",
  });

  const handleExportDataProve = () => {
    var prov = dataProveedores.map((data, i) => {
      return {
        ID: data.prov_id,
        Nombre: data.prov_nombre,
        Direccion: data.prov_direccion,
        Telefono: data.prov_telefono,
        RUC: data.prov_ruc.length > 0 ? data.prov_ruc + " DV " + data.prov_dv : "",
        EMAIL: data.prov_email,
      };
    });

    const csv = generateCsv(csvConfigProveedor)(prov);
    download(csvConfigProveedor)(csv);
  };
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [isLoadingP, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataProveedores, setDataProveedores] = useState([]);
  const [formProveedores, setFormProveedores] = useState({
    prov_id: "",
    prov_suc: "",
    prov_nombre: "",
    prov_direccion: "",
    prov_telefono: "",
    prov_ruc: "",
    prov_dv: "",
    prov_sts: "",
    prov_email: "",
  });

  const onClickUpdateProveedores = () => {
    setLoading(true)
    var body = {
      prov_id: formProveedores.prov_id,
      prov_nombre: formProveedores.prov_nombre,
      prov_direccion: formProveedores.prov_direccion,
      prov_telefono: formProveedores.prov_telefono,
      prov_ruc: formProveedores.prov_ruc,
      prov_dv: formProveedores.prov_dv,
      prov_email: formProveedores.prov_email,
    };

      if (formProveedores.prov_nombre.length > 0) {
        if (
          FIND_DESC(dataProveedores, formProveedores.prov_ruc, formProveedores.prov_id)
        ) {
          setError("Error: Ruc ya esta registrado");
        } else {
          var result = put_pro_proveedor(body);
          result.then((data) => {
            setError("");
            if(data===true){
              fetchProveedores();
              setLoading(false)
            }
            else
              setError("ERROR "+data);
          });
        }
      } else {
        setError("Campos importantes estan vacios");
      }

  };

  const onClickUpdateProveedor_sts = () => {
    var body = {
      prov_id: formProveedores.prov_id,
      prov_sts: 0,
    };
    var result = put_pro_proveedor_sts(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchProveedores();
      else
        setError("ERROR "+data);
    });
  };

  const onClickSaveProveedor = () => {
    setLoading(true)
    var body = {
      prov_nombre: formProveedores.prov_nombre,
      prov_direccion: formProveedores.prov_direccion,
      prov_telefono: formProveedores.prov_telefono,
      prov_ruc: formProveedores.prov_ruc,
      prov_dv: formProveedores.prov_dv,
      prov_email: formProveedores.prov_email,
    };
      if (formProveedores.prov_nombre.length > 0) {
        if (FIND_DESC(dataProveedores, formProveedores.prov_ruc, null)) {
          setError("Error: Ruc ya esta registrado");
        } else {
          var result = post_pro_proveedor(body);
          result.then((data) => {
            setError("");
            if(data===true){
              setLoading(false);
              fetchProveedores();
            }
           
            else
              setError("ERROR "+data);
          });
        }
      } else {
        setError("Campos importantes estan vacios");
      }

  };

  async function fetchProveedores() {
    setLoading(true);
    var response;
    response = await get_pro_proveedor();
    setDataProveedores(response);
    setBasicModalDelete(false);
    setBasicModalEdit(false);
    setBasicModal(false);
    setLoading(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormProveedores({
      ...formProveedores,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchProveedores();
  }, []);

  return (
    <Container>
      {basicModal && (
        <ProveedorModal
          props={formProveedores}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveProveedor}
          toggleSaveEdit={onClickUpdateProveedores}
          onChange={onChange}
          error={error}
          loading={isLoadingP}
        />
      )}
      {basicModalDelete && (
        <DeleteModal
          props={
            formProveedores.prov_nombre +
            " " +
            formProveedores.prov_ruc +
            " " +
            formProveedores.prov_dv
          }
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateProveedor_sts}
          text={"Eliminar este registro?"}
          error={error}
        />
      )}
      <Row>
        <Col className="modal-add-inventario datatable-factura">
          <DATATABLE_PROVEEDORES
            dataProveedores={dataProveedores}
            setBasicModalDelete={setBasicModalDelete}
            handleExportData={handleExportDataProve}
            setFormProveedores={setFormProveedores}
            setBasicModal={setBasicModal}
            setBasicModalEdit={setBasicModalEdit}
            setError={setError}
            fetchProveedores={fetchProveedores}
            isLoadingP={isLoadingP}
          />
        </Col>
      </Row>
    </Container>
  );
}
