import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ReactToPrint from "react-to-print-advanced";
import { Container, Label, Row, Col,NavItem, NavLink  } from "reactstrap";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import CloseIcon from '@mui/icons-material/Close';
import { CLOSE_IMAGE } from "../Componentes/Image";
import { NavLink as RRNavLink, Outlet } from "react-router-dom";

import {MDBIcon, MDBTabs,MDBTabsLink,MDBTabsContent,MDBTabsPane,MDBTable, MDBTableHead, MDBTableBody} from "mdb-react-ui-kit";
import {DATATABLE_MOVIMIENTOS,DATATABLE_DC_COMPRA,DATATABLE_INV_INVENTARIO} from "../Componentes/Datatables"
import { InventarioModal,DeleteModal,RecuentoModal } from "../Componentes/Modal";
import { IconButton } from "@mui/material";
import {
  get_inventario,
  put_dc_compra_sts,
  get_pro_proveedor,
  get_articulo_by_code,
  post_dc_compra,
  get_compra_detalle,
  delete_dc_compra_detalle,
  put_dcd_compra_detalle_row,
  put_dcd_compra_encabezado,
  processInventario,
  get_list_items,
  get_recuento_detalle
  ,get_recuento,
  post_re_recuento,
  procesar_recuento,
  delete_red_recuento_detalle,
  put_re_recuento_sts,
  put_red_recuento_detalle_row,
  put_re_recuento_encabezado,
  get_movimiento,
  get_movimiento_detallado,
  get_compania
} from "../Componentes/Services";
import { ComponentToPrintss,ComponentToPrintsRecuento } from "./ComponentToPrintss";
import {BODY_DC_COMPRA, DC_UPDATE_CANTIDAD,BODY_RE_RECUENTO, RE_UPDATE_CANTIDAD} from "./../Componentes/Util";
import {STATES_DC_COMPRA,STATES_RE_RECUENTO,STATES_MOV_MOVIMIENTO} from "./../Componentes/States";

export const Inventario = () => {
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [basicModalProcesar, setBasicModalProcesar] = useState(false);
  var componentRef = useRef();
  const [dataItems, setDataItems] = useState([]);
  const [tabsActive, setTabsActive] = useState("tab1");
  const [isPrinting, setPrintI] = useState(false);
  const [isLoadingFC, setLoadingFC] = useState(false);
  const [isSaveI, setSaveI] = useState(false);
  const [dataProveedor, setDataProveedor] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [error, setError] = useState("");
  const [error_upc, setError_upc] = useState("");
  const [dataCompra, setDataCompra] = useState([]);
  const [dataCompraDetalle, setOBJCompraDetalle] = useState([]);
  const [formDropDownProveedor, setFormDropDownProveedor] = useState({
    prov_id: "",
    prov_nombre: "",
  });
  const [dataCompania, setDataCompania] = useState({
    bod_suc: "",
    com_nombre: "",
    com_ruc: "",
    com_dv: "",
    com_direccion: "",
    com_telefono: "",
    com_telefono2: "",
    com_email: "",
    com_fax: "",
    com_web: "",
    com_info_interes:"",
    com_cotizacion_info:""
  });
  // setFormCompraDelete({ dc_id: row.original.dc_id, dc_status: 0 })
  const [formCompraDelete, setFormCompraDelete] = useState({dc_id:"",
  dc_status:""});
    const [formProcess, setFormProcess] = useState({dcd_identificador:"",
      dc_proveedor:"",
      dc_id:""});
  const [formCompra, setFormCompra] = useState(STATES_DC_COMPRA({staneName:"formCompra"}));
  const [formCompraDetalle, setFormCompraDetalle] = useState(STATES_DC_COMPRA({staneName:"formCompraDetalle"}));
  const onClickUpdateCompra_sts = () => {
    var body = {
      dc_id: formCompraDelete.dc_id,
      dc_status: formCompraDelete.dc_status,
    };
    put_dc_compra_sts(body);
    setTimeout(() => {
      fetchDCCompra();
      setBasicModalDelete(false)
    }, 1000);
  };
  const handleTabClick = (value) => {
    if (value === tabsActive) {
      return;
    }
    setTabsActive(value);
    setPrintI(false)
  };

  async function fetchDCCompraDetalle(dc_identificador) {
    setLoadingFC(true);
    var response;
    response = await get_compra_detalle(dc_identificador);
    setOBJCompraDetalle(response);
    setLoadingFC(false);
  }

  async function fetch_list_items() {
    var response;
    response = await get_list_items();
    setDataItems(response);
  } 

  async function fetchDCCompra() {
    setLoadingFC(true);
    var response;
    response = await get_inventario();
    setDataCompra(response);
    setBasicModal(false);
    setLoadingFC(false);
    setError("");
    setError_upc("");
  }

  async function fetchProveedor() {
    var response;
    response = await get_pro_proveedor();
    setDataProveedor(response);
  }

  async function fetchArticuloByUpc(art_upc) {
    var response;
    response = await get_articulo_by_code(art_upc);
    if (response[0] !== undefined) {
      setError_upc("");
      setFormCompraDetalle({
        ...formCompraDetalle,
        dcd_articulo: response[0].art_id,
        dcd_precio: response[0].art_costo,
        art_descripcion: response[0].art_descripcion,
        dcd_itbms: response[0].art_itbm_compra,
        art_upc:response[0].art_upc,
        art_itbm_cod_c:response[0].art_itbm_cod_c,
        art_itbm_compra:response[0].art_itbm_compra,
        dcd_cantidad:"1"
      });
    } else {
      setError_upc("Articulo no existe");
    }
  }
  async function fetchCompania() {
    var response;
    response = await get_compania();
    var data = response[0];
    setDataCompania({
      bod_suc: data.bod_suc,
      com_nombre: data.com_nombre,
      com_ruc: data.com_ruc,
      com_dv: data.com_dv,
      com_direccion: data.com_direccion,
      com_telefono: data.com_telefono,
      com_telefono2: data.com_telefono2,
      com_email: data.com_email,
      com_fax: data.com_fax,
      com_web: data.com_web,
      com_info_interes:data.com_info_interes,
      com_cotizacion_info:data.com_cotizacion_info
    });
  }
  useEffect(() => {
    fetchDCCompra();
    fetchProveedor();
    fetch_list_items()
    fetchCompania();
  }, []);

  const onClickSaveCompra = () => {
    setLoadingFC(true)
    if (formCompra.dc_docn.length > 0 && formDropDownProveedor.prov_id.length > 0 && dataCompraDetalle[0] !== undefined) {
      var body =  BODY_DC_COMPRA({dataCompraDetalle,formCompra,formDropDownProveedor})
      post_dc_compra(body);
      setSaveI(true);
      setTimeout(() => {
        setSaveI(false);
        fetchDCCompra();
        setLoadingFC(false)
      }, 2000);
    } else {
      setError("Campos vacios");
    }
  };

  function onChange(event) {
    const { value, name } = event.target;
    setFormCompra({
      ...formCompra,
      [name]: value,
    });
    setError("");
  }

  function onChangeDC_detail(event) {
    const { value, name } = event.target;
    setFormCompraDetalle({
      ...formCompraDetalle,
      [name]: value,
    });

    if (event.key === "Enter" && name === "art_upc") {
      fetchArticuloByUpc(value);
    }

    setError("");
    setError_upc("");
  }

  function handleKeyPress(event) {

    const { value, name } = event.target;
    if (event.key === "Enter" && name === "art_upc") {
      fetchArticuloByUpc(value);
    }

    if (event.key === "Enter" && name === "dcd_cantidad") {
      if(basicModalEdit){
        DC_UPDATE_CANTIDAD({dataCompraDetalle,formCompraDetalle,formCompra})
        setTimeout(() => {
          fetchDCCompraDetalle(formCompra.dc_identificador)
        }, 2000);
      }
      else{
        var flag = false;
        var itbms=0;
        var cantidad =0;
        var objItems = dataCompraDetalle.map((data, i) => {
          if (data.dcd_articulo === formCompraDetalle.dcd_articulo &&
            data.art_descripcion === formCompraDetalle.art_descripcion) {
            cantidad = (Number(data.dcd_cantidad) + Number(formCompraDetalle.dcd_cantidad)).toFixed(2);
            itbms = ((Number(cantidad)*Number(data.dcd_precio))*Number(data.art_itbm_compra)).toFixed(4);
            flag = true;
            data.dcd_cantidad = Number(cantidad).toFixed(2);
            data.dcd_itbms = itbms;
            data.dcd_total = ((Number(cantidad)*Number(formCompraDetalle.dcd_precio))+(Number(itbms))).toFixed(4);
          }
          return data;
        });
        if (!flag) {
          itbms = ((Number(formCompraDetalle.dcd_cantidad)*Number(formCompraDetalle.dcd_precio))*Number(formCompraDetalle.art_itbm_compra)).toFixed(4);
          setOBJCompraDetalle([...dataCompraDetalle, ...[{...formCompraDetalle,
            dcd_itbms:itbms,
            dcd_total:((Number(formCompraDetalle.dcd_cantidad)*Number(formCompraDetalle.dcd_precio))+(Number(itbms))).toFixed(4),
          }]]);
  
        } else {
          setOBJCompraDetalle(objItems);
        }
      }
      setFormCompraDetalle(STATES_DC_COMPRA({staneName:"formCompraDetalle"}));
    }
    setError("");
  }

  const onChangeDropdown = (selectedOptions) => {
    setFormDropDownProveedor({
      ...formDropDownProveedor,
      prov_id: selectedOptions.value,
      prov_nombre: selectedOptions.label,
    });
  };

  var ObjProveedor = [];
  dataProveedor.map((item) => {
    ObjProveedor.push({
      value: item.prov_id,
      label: item.prov_nombre + " / " + item.prov_ruc,
      objName: "Proveedor",
    });
  });

  const deleteRow = (row) => {
    if(basicModalEdit){
      delete_dc_compra_detalle(row.original)
      setTimeout(() => {
        fetchDCCompraDetalle(row.original.dcd_identificador)
      }, 1000);
    }
    else{
      var value = dataCompraDetalle.filter((data, i) => {
        if (i !== row.index) return data;
      });
      setOBJCompraDetalle(value);
    }
  };

  const update_dcd_compra_detalle_row = (values,dcd_identificador) => {
    put_dcd_compra_detalle_row(values)
    setTimeout(() => {
      fetchDCCompraDetalle(dcd_identificador)
    }, 1000);
  };

  const toggleSaveEncabezado = () => {
    var body={
      dc_id:formCompra.dc_id,
      dc_docn:formCompra.dc_docn,
      dc_proveedor:formDropDownProveedor.prov_id,	
      dc_descripcion:formCompra.dc_descripcion
    }
    put_dcd_compra_encabezado(body)
    setTimeout(() => {
      get_inventario()
    }, 1000);
  };
  
  const processarInventario = () => {
    var body={
      dcd_identificador:formProcess.dcd_identificador,
      dc_proveedor:formProcess.dc_proveedor,
      dc_id:formProcess.dc_id
    }
    processInventario(body)
    setTimeout(() => {
      fetchDCCompra();
      setBasicModalProcesar(false)
    }, 1000);
  };

  const onBlurF = (event,row) => {
      var data = dataCompraDetalle.filter((datos,i)=>{
        if(i !== row.index){
          return datos
        }
      })
      setOBJCompraDetalle(dataCompraDetalle => [...data, ...
        [{
          art_descripcion: row.original.art_descripcion,
          dcd_cantidad: event.target.value,
          dcd_itbms: row.original.dcd_itbms,
          dcd_precio: row.original.dcd_precio,
          dcd_total: row.original.dcd_total,
          dcd_articulo: row.original.dcd_articulo,
        }]
      ])
  };
  return (
    <Container fluid>
      {basicModalProcesar && (
        <DeleteModal
          props={"Inventario N° "+formProcess.dc_id}
          isOpen={basicModalProcesar}
          toggle={setBasicModalProcesar}
          toggleDelete={processarInventario}
          text={"Procesar inventario?"}
          error={""}
        />
      )}
      {basicModalDelete && (
        <DeleteModal
          props={"Inventario N° "+formCompraDelete.dc_id}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateCompra_sts}
          text={"Eliminar este registro?"}
          error={""}
        />
      )}
      {basicModal && (
        <InventarioModal
          props={formCompra}
          dataCompraDetalle={dataCompraDetalle}
          propsDCDetail={formCompraDetalle}
          formDropdown={formDropDownProveedor}
          propsDropdown={ObjProveedor}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveCompra}
          onChange={onChange}
          onChangeDC_detail={onChangeDC_detail}
          handleKeyPress={handleKeyPress}
          onChangeDropdown={onChangeDropdown}
          onBlurs={onBlurF}
          error={error}
          error_upc={error_upc}
          isSaveI={isSaveI}
          deleteRow={deleteRow}
          setOBJCompraDetalle={setOBJCompraDetalle}
          fetchDCCompra={fetchDCCompra}
          update_dcd_compra_detalle_row={update_dcd_compra_detalle_row}
          toggleSaveEncabezado={toggleSaveEncabezado}
          dataItems={dataItems}
          selectItem={fetchArticuloByUpc}
          setFormCompraDetalle={setFormCompraDetalle}
          loading={isLoadingFC}
        />
      )}
      <Row className="background-divs">
        <Col xs={12} className="text-center">
          <Label className="font-weight-bold-title">INVENTARIO</Label>
        </Col>
      </Row>
      <Row>
      <MDBTabs className="d-flex-none custom-navs">
        <Row>
          <Col xs={3} >
            <MDBTabsLink onClick={() => handleTabClick("tab1")} active={tabsActive === "tab1"}>
              <MDBIcon fas icon="money-bill" className="me-2" /> Factura de compra
            </MDBTabsLink>
            </Col>
            <Col xs={3}>
            <MDBTabsLink onClick={() => handleTabClick("tab2")} active={tabsActive === "tab2"}>
              <MDBIcon fas icon="cash-register" className="me-2" /> Movimientos
            </MDBTabsLink>
            </Col>
            <Col xs={3}>
            <MDBTabsLink onClick={() => handleTabClick("tab3")} active={tabsActive === "tab3"}>
              <MDBIcon fas icon="truck-moving" className="me-2" /> Inventario
            </MDBTabsLink>
            </Col>
            <Col xs={3} className="text-end">
            <NavLink tag={RRNavLink} to="/user/home" className="font-w-bold   menu-l-c">
          <img src={CLOSE_IMAGE} className="src-image" alt="..."/>
        </NavLink>
        </Col>
          </Row>
        </MDBTabs>
        <MDBTabsContent>
          <MDBTabsPane show={tabsActive === "tab1"}>
          <FacturasCompra/>
          </MDBTabsPane>
          <MDBTabsPane show={tabsActive === "tab2"}>
            <Movimientos/>
          </MDBTabsPane>
          <MDBTabsPane show={tabsActive === "tab3"}>
          <Inventario/>
          </MDBTabsPane>
        </MDBTabsContent>
      </Row>
      <Row className="background-divs fixed-div">
        <Col xs={12} className="text-center">
          <Label className="font-weight-bold-title">.</Label>
        </Col>
      </Row>
    </Container>
  );
  function FacturasCompra(){
    return <>
          {isPrinting ? (
          <React.Fragment>
            <Row>
                  <Col xs={10}></Col>
                  <Col xs={2}>
                    <IconButton className="float-right-btn"
                      onClick={() => {
                        setPrintI(false);
                        setFormCompra(STATES_DC_COMPRA({staneName:"formCompra"}));
                        setOBJCompraDetalle([]);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <ReactToPrint
                      trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                      content={() => componentRef} />
                  </Col>
                  <Col xs={12} className="text-center scrol-table2">
                  <ComponentToPrintss
                    ref={el => (componentRef = el)}
                    props={dataCompraDetalle}
                    formCompra={formCompra}
                    formCompania={dataCompania}
                  />
                    </Col>
                </Row>
          </React.Fragment>
        ) : (
          <Row>
          <Col className="modal-add-inventario datatable-factura" xs={12}>
          <DATATABLE_DC_COMPRA
          dataCompra ={dataCompra} setFormCompraDelete={setFormCompraDelete} setBasicModalDelete={setBasicModalDelete}
          setBasicModalEdit={setBasicModalEdit} setBasicModal={setBasicModal} setFormCompra={setFormCompra} 
          fetchDCCompraDetalle={fetchDCCompraDetalle} setFormDropDownProveedor={setFormDropDownProveedor} setPrintI={setPrintI} setFormProcess={setFormProcess}
          setBasicModalProcesar={setBasicModalProcesar} fetchDCCompra ={fetchDCCompra}setFormCompraDetalle={setFormCompraDetalle} setOBJCompraDetalle={setOBJCompraDetalle}
          />
            
          </Col>
          </Row>

        )}

    </>
  }
  function Inventario(){
    const [dataRecuento, setDataRecuento] = useState([]);
    const [isModalRecuento, setBasicModalRecuento] = useState(false);
    const [error, setError] = useState("");
    const [isLoadingI, setLoadingI] = useState(false);
    const [formRecuento, setFormRecuento] = useState(STATES_RE_RECUENTO({staneName:"formRecuento"}));
    const [isPrinting, setPrintI] = useState(false);
    const [dataRecuentoDetalle, setOBJRecuentoDetalle] = useState([]);
    const [basicModalDeleteRe, setBasicModalDeleteRe] = useState(false);
    const [basicModalProcesarRe, setBasicModalProcesarRe] = useState(false);
    const [formRecuentoDelete, setFormRecuentoDelete] = useState({re_id:"",re_status:""});
    const [formProcessRe, setFormProcessRe] = useState({red_identificador:"",re_id:""});
    const [basicModalRe, setBasicModalRe] = useState(false);
    const [basicModalEditRe, setBasicModalEditRe] = useState(false);
    const [formRecuentoDetalle, setFormRecuentoDetalle] = useState(STATES_RE_RECUENTO({staneName:"formRecuentoDetalle"}));
    const [error_upc, setError_upc] = useState("");
    const [isSaveI, setSaveI] = useState(false);

    const deleteRowRe = (row) => {
      if(basicModalEditRe){
        delete_red_recuento_detalle(row.original)
        setTimeout(() => {
          fetchRecuentoDetalle({re_identificador:row.original.re_identificador});
        }, 1000);
      }
      else{
        var value = dataRecuentoDetalle.filter((data, i) => {
          if (i !== row.index) return data;
        });
        setOBJRecuentoDetalle(value);
      }
    };

    async function fetchRecuentoDetalle({re_identificador}) {
      setLoadingI(true);
      var response;
      response = await get_recuento_detalle(re_identificador);
      setOBJRecuentoDetalle(response);
      setLoadingI(false);
    }

    function onChangeRe(event) {
      const { value, name } = event.target;
      setFormRecuento({
        ...formRecuento,
        [name]: value,
      });
      setError("");
    }

      function onChangeRed_detail(event) {
        const { value, name } = event.target;
        setFormRecuentoDetalle({
          ...formRecuentoDetalle,
          [name]: value,
        });

        if (event.key === "Enter" && name === "art_upc") {
          fetchArticuloByUpc(value);
        }

        setError("");
        setError_upc("");
      }
      const onClickSaveRecuento = () => {
        setLoadingI(true)
        if (dataRecuentoDetalle[0] !== undefined) {

          var body =  BODY_RE_RECUENTO({dataRecuentoDetalle,formRecuento})
      post_re_recuento(body);
          // // setSaveI(true);
          setTimeout(() => {
            // setSaveI(false);
            setBasicModalRe(false);
            fetchRecuento();
            setLoadingI(false)
          }, 2000);
        } else {
          setError("Campos vacios");
        }
      };

      async function fetchArticuloByUpcRe(art_upc) {
        var response;
        response = await get_articulo_by_code(art_upc);
        if (response[0] !== undefined) {
          setError_upc("");
          setFormRecuentoDetalle({
            ...formRecuentoDetalle,
            red_articulo:response[0].art_id,
            red_cantidad: "1",
            art_upc: response[0].art_upc,
            art_descripcion: response[0].art_descripcion,
          });
        } else {
          setError_upc("Articulo no existe");
        }
      }

      function handleKeyPressRe(event) {
        const { value, name } = event.target;
        if (event.key === "Enter" && name === "art_upc") {
          fetchArticuloByUpcRe(value);
        }

        if (event.key === "Enter" && name === "red_cantidad") {
          if(basicModalEditRe){

            RE_UPDATE_CANTIDAD({dataRecuentoDetalle,formRecuentoDetalle,formRecuento})
            setTimeout(() => {
              fetchRecuentoDetalle({re_identificador:formRecuento.re_identificador});
            }, 2000);
          }
          else{
            var flag = false;
            var cantidad =0;
            var objItems = dataRecuentoDetalle.map((data, i) => {
              if (data.red_articulo === formRecuentoDetalle.red_articulo) {
                cantidad = Number(data.red_cantidad) + Number(formRecuentoDetalle.red_cantidad);
                flag = true;
                data.red_cantidad = Number(cantidad);
              }
              return data;
            });
            if (!flag) {
              setOBJRecuentoDetalle([...dataRecuentoDetalle, ...[{...formRecuentoDetalle}]]);
            } else {
              setOBJRecuentoDetalle(objItems);
            }
          }
          setFormRecuentoDetalle(STATES_RE_RECUENTO({staneName:"formRecuentoDetalle"}));
        }
        setError("");
        setLoadingI(false)
      }

    async function fetchRecuento() {
      setLoadingI(true);
      var response;
      response = await get_recuento();
      setDataRecuento(response);
      // setBasicModalRecuento(false);
      setError("");
    }
  
    useEffect(() => {
      fetchRecuento();
    }, []);

    const update_red_recuento_detalle_row_re = (values,red_identificador) => {
      put_red_recuento_detalle_row(values)
      setTimeout(() => {
        fetchRecuentoDetalle({re_identificador:red_identificador})
        
      }, 1000);
    };

    const onClickUpdateRecuento_sts = () => {
      var body = {
        re_id: formRecuentoDelete.re_id,
        re_status: "0",
      };
      put_re_recuento_sts(body);
      setTimeout(() => {
        fetchRecuento();
        setBasicModalDeleteRe(false)
      }, 1000);
    };

    const toggleSaveEncabezadoRe = () => {
      var body={
        re_id:formRecuento.re_id,
        re_descripcion:formRecuento.re_descripcion
      }
      put_re_recuento_encabezado(body);
      setTimeout(() => {
        
        fetchRecuentoDetalle({re_identificador:formRecuento.re_identificador})
      }, 1000);
    };
    const processarInventarioRe = () => {
      var body={
        red_identificador:formProcessRe.red_identificador,
        re_id:formProcessRe.re_id
      }
      procesar_recuento(body)
      setTimeout(() => {
        fetchRecuento();
        setBasicModalProcesarRe(false)
      }, 1000);
    };
    return <>
     {basicModalProcesarRe && (
        <DeleteModal
          props={"Inventario N° "+formProcessRe.re_id}
          isOpen={basicModalProcesarRe}
          toggle={setBasicModalProcesarRe}
          toggleDelete={processarInventarioRe}
          text={"Procesar inventario?"}
          error={""}
        />
      )}

     {basicModalDeleteRe && (
        <DeleteModal
          props={"Inventario N° "+formRecuentoDelete.re_id}
          isOpen={basicModalDeleteRe}
          toggle={setBasicModalDeleteRe}
          toggleDelete={onClickUpdateRecuento_sts}
          text={"Eliminar este registro?"}
          error={""}
        />
      )}
    {basicModalRe && (
        <RecuentoModal
          props={formRecuento}
          dataRecuentoDetalle={dataRecuentoDetalle}
          formRecuentoDetalle={formRecuentoDetalle}
          isOpen={basicModalRe}
          basicModalEditRe={basicModalEditRe}
          toggle={setBasicModalRe}
          toggleSave={onClickSaveRecuento}
          onChange={onChangeRe}
          onChangeRed_detail={onChangeRed_detail}
          handleKeyPress={handleKeyPressRe}
          onBlurs={onBlurF}
          error={error}
          error_upc={error_upc}
          isSaveI={isSaveI}
          deleteRow={deleteRowRe}
          setOBJRecuentoDetalle={setOBJRecuentoDetalle}
          fetchRecuento={fetchRecuento}
          dataItems={dataItems}
          update_red_recuento_detalle_row_re={update_red_recuento_detalle_row_re}
          toggleSaveEncabezado={toggleSaveEncabezadoRe}
          dataRecuento={dataRecuento}
          selectItem={fetchArticuloByUpcRe}
          setFormRecuentoDetalle={setFormRecuentoDetalle}
          loading={isLoadingI}
        />
      )}

            {isPrinting ? (
            <React.Fragment>
            <Row>
                  <Col xs={10}></Col>
                  <Col xs={2}>
                    <IconButton className="float-right-btn"
                      onClick={() => {
                        setPrintI(false);
                        setFormCompra(STATES_RE_RECUENTO({staneName:"formRecuento"}));
                        setOBJRecuentoDetalle([]);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <ReactToPrint
                      trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                      content={() => componentRef} />
                  </Col>
                  <Col xs={12} className="text-center scrol-table2">
                  <ComponentToPrintsRecuento
                    ref={el => (componentRef = el)}
                    props={dataRecuentoDetalle}
                    formRecuento={formRecuento}
                    formCompania={dataCompania}
                  />
                    </Col>
                </Row>
          </React.Fragment>
        ) : (
          <>
            <Row>
            <Col className="modal-add-inventario datatable-factura">
            <DATATABLE_INV_INVENTARIO dataRecuento={dataRecuento} setFormRecuentoDelete ={setFormRecuentoDelete} setBasicModalDeleteRe  ={setBasicModalDeleteRe}
              fetchRecuentoDetalle  ={fetchRecuentoDetalle}
              setFormRecuento ={setFormRecuento}setBasicModalRe ={setBasicModalRe}
              setBasicModalEditRe ={setBasicModalEditRe}
              setPrintI ={setPrintI} 
              setFormProcessRe ={setFormProcessRe} 
              setBasicModalProcesarRe ={setBasicModalProcesarRe}
              setOBJRecuentoDetalle ={setOBJRecuentoDetalle}
              fetchRecuento ={fetchRecuento}
              setFormRecuentoDetalle ={setFormRecuentoDetalle}/>
            </Col>
            </Row>

          </>

        )}

    </>
  }

  function Movimientos(){
    const [dataMovimiento, setDataMovimiento] = useState([]);
    const [isOpenTabMovimientoDetail, setTabMovientoDetail] = useState(false);
    const [errorM, setErrorMov] = useState("");
    const [isLoadingM, setLoadingM] = useState(false);
    const [formMovimiento, setFormMovimiento] = useState(STATES_MOV_MOVIMIENTO({staneName:"formMovimiento"}));
    const [isPrintingM, setPrintM] = useState(false);
    const [dataMovimientoVentas, setOBJMovimientoVentas] = useState([]);
    const [dataMovimientoCompra, setOBJMovimientoCompra] = useState([]);
    const [dataMovimientoInventario, setOBJMovimientoInventario] = useState([]);
    const [dataMovimientoRecuento, setOBJMovimientoRecuento] = useState([]);
    
    async function fetchMovimiento() {
      setLoadingM(true);
      var response;
      response = await get_movimiento();
      setDataMovimiento(response);
      setLoadingM(false);
    }

    async function fetchMovimientoDetallado({art_id}) {
      setLoadingM(true);
      var response;
      response = await get_movimiento_detallado(art_id);
      setOBJMovimientoVentas(response.ventas);
      setOBJMovimientoCompra(response.dc_compra);
      setOBJMovimientoRecuento(response.re_recuento);
      setLoadingM(false);
    }
    useEffect(() => {
      fetchMovimiento();
    }, []);
    var cantidad_ventas=0;
    var cantidad_compra =0;
    var cantidad_inventario =0;
    return <>
            {isOpenTabMovimientoDetail ? (
            <React.Fragment>
              <>
              <Row>
                <Col xs={12}>
                <IconButton className="float-right-btn"
                      onClick={() => {
                        setTabMovientoDetail(false);
                        setOBJMovimientoVentas([]);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                </Col>
                <Col xs={12} className="text-center ov-y-inv">
                  <Label className="report-title">Salida venta</Label>
                  <MDBTable striped className="custom-table">

                    <MDBTableHead  className="datatable-factura-r">
                      <tr>
                        <th>N. FACTURA</th>
                        <th>FECHA</th>  
                        <th>VENDEDOR</th>
                        <th>CANTIDAD</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {
                        dataMovimientoVentas.map((data, i) => {
                          cantidad_ventas= cantidad_ventas + Number(data.fcd_cantidad);
                          return <tr>
                            <th>{data.fac_id.toString().padStart(10, "0")}</th>
                            <th>{moment(data.fac_fecha).format("YYYY-MM-DD") }</th>
                            <td>{data.usr_nombre}</td>
                            <td>{data.fcd_cantidad}</td>
                          </tr>
                        })
                      }
                       <tr>
                        <th></th>
                        <th></th>
                        <th><Label className="fw-bold">TOTAL</Label></th>
                        <th><Label className="fw-bold">{Number(cantidad_ventas).toFixed(2)}</Label> </th>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>

                </Col>
                <Col xs={12} className="text-center ov-y-inv">
                  <Label className="report-title">Entrada factura de compra</Label>
                  <MDBTable striped className="custom-table">
                    <MDBTableHead className="datatable-factura-r">
                      <tr>
                        <th>N. DOCUMENTO</th>
                        <th>FECHA DOCUMENTO</th>  
                        <th>FECHA PROCESADO</th>  
                        <th>CODIGO</th>
                        <th>ARTÍCULO</th>
                        <th>CANTIDAD</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {
                        dataMovimientoCompra.map((data, i) => {
                          cantidad_compra = cantidad_compra + Number(data.dcd_cantidad);
                          return <tr>
                            <th>{data.dc_docn}</th>
                            <th>{moment(data.fac_fecha).format("YYYY-MM-DD") }</th>
                            <th>{moment(data.dc_fecha_procesado).format("YYYY-MM-DD") }</th>
                            <td>{data.art_upc}</td>
                            <td>{data.art_descripcion}</td>
                            <td>{data.dcd_cantidad}</td>
                          </tr>
                        })
                      }
                      <tr>
                        <th></th>
                        <th></th>  
                        <th></th>
                        <th></th>
                        <th><Label className="fw-bold">TOTAL</Label></th>
                        <th><Label className="fw-bold">{Number(cantidad_compra).toFixed(2)}</Label> </th>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </Col>
                <Col xs={12} className="text-center">
                  <Label className="report-title">Entrada Inventario</Label>
                  <MDBTable striped className="custom-table">
                    <MDBTableHead className="datatable-factura-r">
                      <tr>
                        <th>N. INVENTARIO</th>
                        <th>FECHA INVENTARIO</th>  
                        <th>FECHA PROCESADO</th>  
                        <th>CÓDIGO</th>
                        <th>ARTÍCULO</th>
                        <th>CANTIDAD</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {
                        dataMovimientoRecuento.map((data, i) => {
                          cantidad_inventario = cantidad_inventario + Number(data.red_cantidad);
                          return <tr>
                            <th>{data.re_id}</th>
                            <th>{moment(data.re_fecha).format("YYYY-MM-DD") }</th>
                            <th>{moment(data.re_fecha_procesado).format("YYYY-MM-DD") }</th>
                            <td>{data.art_upc}</td>
                            <td>{data.art_descripcion}</td>
                            <td>{data.red_cantidad}</td>
                          </tr>
                        })
                      }
                      <tr>
                        <th></th>
                        <th></th>  
                        <th></th>
                        <th></th>
                        <th><Label className="fw-bold">TOTAL</Label></th>
                        <th><Label className="fw-bold">{Number(cantidad_inventario).toFixed(2)}</Label> </th>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </Col>
              </Row>
            </>
          </React.Fragment>
        ) : (
          <>
            <Row>
            <Col className="modal-add-inventario datatable-factura">
            <DATATABLE_MOVIMIENTOS dataMovimiento ={dataMovimiento} setTabMovientoDetail={setTabMovientoDetail}fetchMovimientoDetallado  ={fetchMovimientoDetallado}/>
          </Col>
            </Row>

          </>

        )}

    </>
  }
};