import React, { useState, useEffect, useContext,useRef } from "react";
import moment from "moment";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import {STATES_ART_ARTICULO} from "../States";
import { CLOSE_IMAGE } from "../Image";
import { NavLink as RRNavLink, Outlet } from "react-router-dom";
import "./../www/style.css";
import ClearIcon from '@mui/icons-material/Clear';
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ReactToPrint from "react-to-print-advanced";
import {DATATABLE_ARTICULO} from "../Datatables";
import {ComponentToPrintsBarcode} from "./../../Inventario/ComponentToPrintss";
import {ArticuloModal,DeleteModal,ImageTooltipModal} from "./../Modal";
import { IconButton } from "@mui/material";
import { FIND_DESC,EXPORT_CSV_ARTICULOS } from "./../Util";
import { get_cat_categoria,get_bod_bodega, get_mar_marca,get_pro_proveedor,get_art_articulo,post_art_articulo,put_art_articulo,put_art_articulo_sts,
         imgURLServer,get_itbms,get_artb_bodega_by_articulo,
         put_artb_sts,post_artb_bodega,put_delete_img} from "../Services";
import { Container, Label, Row, Col,NavLink } from "reactstrap";

export const Articulos = ()=> {
  const [srcImage, setSrcImage] = useState("");
  const [ImgName, setImgName] = useState("");
  var componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [printToolti, setPrintTooltip] = useState(false);
  const [basicModalImageTooltip, setModalImageTooltip] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [images, setImages] = React.useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [dataArticulos, setDataArticulos] = useState([]);
  const [error, setError] = useState("");
  const [dataCategorias, setDataCategorias] = useState([]);
  const [dataBodegas, setDataBodegas] = useState([]);
  const [dataMarca, setDataMarca] = useState([]);
  const [dataItbms, setDataItbms] = useState([]);
  const [dataProveedor, setDataProveedor] = useState([]);
  const [dataArticuloBodega, setDataArticuloBodega] = useState([]);
  const [formArticulos, setFormArticulos] = useState(STATES_ART_ARTICULO("formArticulos"));
  const [formDropDown, setFormDropDown] = useState(STATES_ART_ARTICULO("formDropDown"));

  const handleExportCsv = () => {
    EXPORT_CSV_ARTICULOS({dataArticulos})
  };

  const onChangeImage = (imageList) => {
    setFormArticulos({
      ...formArticulos,
      art_imgdataurl: imageList[0]===undefined?"":imageList[0].data_url,
      art_imgType: imageList[0]===undefined?"":imageList[0].file.type,
    });
    setImages(imageList);
  };
  const onClickUpdateArticulos = () => {
    setLoading(true)
    var body = {
      art_id: formArticulos.art_id,
      art_upc: formArticulos.art_upc.replace(/ /g, ""),
      art_categoria:formDropDown.cat_id.length > 0 ? formDropDown.cat_id : formArticulos.art_categoria,
      art_proveedor:formDropDown.prov_id.length > 0 ? formDropDown.prov_id : formArticulos.art_proveedor,
      art_marca: formDropDown.mar_id.length > 0 ? formDropDown.mar_id : formArticulos.art_marca,
      art_descripcion: formArticulos.art_descripcion,
      art_serie: formArticulos.art_serie,
      art_costo: formArticulos.art_costo,
      art_precio: formArticulos.art_precio,
      art_precio2: formArticulos.art_precio2,
      art_precio3: formArticulos.art_precio3,
      art_referencia: formArticulos.art_referencia,
      art_linea_roja: formArticulos.art_linea_roja,
      art_img: formArticulos.art_imgdataurl !== undefined ? "1" : "0",
      art_itbm_venta: formDropDown.itbm_porc_v,
      art_itbm_cod_v: formDropDown.itbm_cod_venta,
      art_itbm_compra: formDropDown.itbm_porc_c,
      art_itbm_cod_c: formDropDown.itbm_cod_compra,
      art_imgdataurl: formArticulos.art_imgdataurl,
      art_imgType: formArticulos.art_imgType !== undefined ? formArticulos.art_imgType : "none",
      art_imgURLServer: imgURLServer,
      art_imgName:formArticulos.art_imgdataurl !== undefined? moment(new Date()).format("YYYYMMDDHHmmss"): formArticulos.art_imgName,
      art_restaurante:formArticulos.art_restaurante,
    };

    if (formArticulos.art_upc.length > 0 &&
      formDropDown.cat_id.length > 0 &&
      formDropDown.prov_id.length > 0 &&
      formDropDown.mar_id.length > 0 &&
      formArticulos.art_descripcion.length > 0 &&
      formArticulos.art_precio.length > 0 &&
      formDropDown.itbm_cod_compra.length > 0 &&
      formDropDown.itbm_cod_venta.length > 0) {
      if (FIND_DESC(dataArticulos, formArticulos.art_upc, formArticulos.art_id)) {
        setError("Error: CODIGO ya esta registrado");
      } else {
        if (dataArticuloBodega[0] !== undefined) {
          var result = put_art_articulo(body);
          result.then((data) => {
            setError("");
            if(data===true)
            {
              setLoading(false)
              fetchArticulos();
            }
              
            else
              setError("ERROR "+data);
          });
        } else {
          setError("Seleccione una bodega");
        }
      }
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  const onClickUpdateArticulo_sts = () => {
    var body = {
      art_id: formArticulos.art_id,
      art_sts: 0,
    };
    var result = put_art_articulo_sts(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchArticulos();
      else
        setError("ERROR "+data);
    });
  };

  const onClickSaveArticulos = () => {
    setLoading(true)
    var body = {
      art_upc: formArticulos.art_upc.replace(/ /g, ""),
      art_categoria: formDropDown.cat_id,
      art_proveedor: formDropDown.prov_id,
      art_marca: formDropDown.mar_id,
      art_descripcion: formArticulos.art_descripcion,
      art_serie: formArticulos.art_serie,
      art_costo: formArticulos.art_costo,
      art_precio: formArticulos.art_precio,
      art_precio2: formArticulos.art_precio2,
      art_precio3: formArticulos.art_precio3,
      art_referencia: formArticulos.art_referencia,
      art_linea_roja: formArticulos.art_linea_roja,
      art_img: formArticulos.art_imgdataurl.length > 0 ? "1" : "0",
      art_itbm_venta: formDropDown.itbm_porc_v,
      art_itbm_cod_v: formDropDown.itbm_cod_venta,
      art_itbm_compra: formDropDown.itbm_porc_c,
      art_itbm_cod_c: formDropDown.itbm_cod_compra,
      art_imgdataurl: formArticulos.art_imgdataurl,
      art_imgType: formArticulos.art_imgType !== "" ? formArticulos.art_imgType : "none",
      art_imgURLServer: imgURLServer,
      inv_id:formArticulos.inv_id,
      inv_qty:formArticulos.inv_qty,
      art_imgName:formArticulos.art_imgdataurl !== undefined? moment(new Date()).format("YYYYMMDDHHmmss") : formArticulos.art_imgName,
      data_art_bodega: dataArticuloBodega,
      art_restaurante:formArticulos.art_restaurante
    };
    if (
      formArticulos.art_upc.length > 0 &&
      formDropDown.cat_id.length > 0 &&
      formDropDown.prov_id.length > 0 &&
      formDropDown.mar_id.length > 0 &&
      formArticulos.art_descripcion.length > 0 &&
      formArticulos.art_precio.length > 0 &&
      formDropDown.itbm_cod_compra.length > 0 &&
      formDropDown.itbm_cod_venta.length > 0
    ) {
      if (FIND_DESC(dataArticulos, formArticulos.art_upc, null)) {
        setError("Error: CODIGO ya esta registrado");
      } else {
        if (dataArticuloBodega[0] !== undefined) {
          var result = post_art_articulo(body);
          result.then((data) => {
            setError("");
            if(data===true){
              fetchArticulos();
              setLoading(false)}
            else
              setError("ERROR "+data);
          });
        } else {
          setError("Seleccione una bodega");
        }
      }
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchArticulos() {
    var response = await get_art_articulo();
    setDataArticulos(response);
    setBasicModal(false);
    setBasicModalDelete(false);
    setBasicModalEdit(false);
    setError("");
  }

  async function fetchBodegaByArticulo(art_id) {
    var response = await get_artb_bodega_by_articulo(art_id);
    setDataArticuloBodega(response);
  }

  async function fetchCategorias() {
    var response = await get_cat_categoria();
    setDataCategorias(response);
  }

  async function fetchBodegas() {
    var response = await get_bod_bodega();
    setDataBodegas(response);
  }

  async function fetchMarcas() {
    var response = await get_mar_marca();
    setDataMarca(response);
  }

  async function fetchItbms() {
    var response = await get_itbms();
    setDataItbms(response);
  }

  async function fetchProveedor() {
    var response = await get_pro_proveedor();
    setDataProveedor(response);
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormArticulos({
      ...formArticulos,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchArticulos();
  }, []);

  var ObjMarca = [];
  var ObjCategoria = [];
  var ObjProveedor = [];
  var ObjBodega = [];
  var ObjItbms_venta = [];
  var ObjItbms_compra = [];
  dataMarca.map((item) => {
    ObjMarca.push({
      value: item.mar_id,
      label: item.mar_descripcion,
      objName: "Marca",
    });
  });
  dataCategorias.map((item) => {
    ObjCategoria.push({
      value: item.cat_id,
      label: item.cat_descripcion,
      objName: "Categoria",
    });
  });
  dataProveedor.map((item) => {
    ObjProveedor.push({
      value: item.prov_id,
      label: item.prov_nombre,
      objName: "Proveedor",
    });
  });
  dataBodegas.map((item) => {
    ObjBodega.push({
      value: item.bod_id,
      label: item.bod_nombre,
      objName: "Bodega",
    });
  });
  dataItbms.map((item) => {
    ObjItbms_venta.push({
      value: item.itbm_cod_number,
      label: item.itbm_descripcion,
      itbm_porc_v: item.itbm_porc,
      objName: "itbms_venta",
    });
  });
  dataItbms.map((item) => {
    ObjItbms_compra.push({
      value: item.itbm_cod_number,
      label: item.itbm_descripcion,
      itbm_porc_c: item.itbm_porc,
      objName: "itbms_compra",
    });
  });
  var propsDropdown = {
    categorias: ObjCategoria,
    marcas: ObjMarca,
    bodegas: ObjBodega,
    proveedor: ObjProveedor,
    itbms_venta: ObjItbms_venta,
    itbms_compra: ObjItbms_compra,
  };

  const removeImage = (data) => {
    if (basicModalEdit) {
      var body = {
        art_id: data,
      };

      var result = put_delete_img(body);
      result.then((data) => {
        setError("");
        if(data===true)
          setFormArticulos({
            ...formArticulos,
            art_imgdataurl: "",
            art_imgType:"",
            art_imgName:"",
            art_img:""
          });
        else
          setError("ERROR "+data);
      });

    }
  };

  const onRemoveBodega = (data) => {
    if (basicModalEdit) {
      var body = {
        artb_id: data.artb_id,
      };
      put_artb_sts(body);
      var result = put_artb_sts(body);
      result.then((data) => {
        setError("");
        if(data===true)
          fetchBodegaByArticulo(data.artb_articulo);
        else
          setError("ERROR "+data);
      });
    } else {
      var value = dataArticuloBodega.filter((values, i) => {
        if (values.artb_bodega !== data.artb_bodega) return data;
      });
      setDataArticuloBodega(value);
    }
  };

  const openModalArticulos = () => {
    fetchCategorias();
    fetchBodegas();
    fetchMarcas();
    fetchProveedor();
    fetchItbms();
    setBasicModal(true);
    setFormArticulos(STATES_ART_ARTICULO("formArticulos"))
  }
  const onChangeDropdown = (selectedOptions) => {
    if (selectedOptions.objName === "Bodega") {
      var value = false;
      dataArticuloBodega.filter((values, i) => {
        if (values.artb_bodega === selectedOptions.value) {
          value = true;
          return true;
        }
      });

      if (!value) {
        if (basicModalEdit) {
          var result = post_artb_bodega({
            artb_articulo: formArticulos.art_id,
            artb_bodega: selectedOptions.value,
            artb_code:formArticulos.art_id+"_"+selectedOptions.value
          });;
          result.then((data) => {
            setError("");
            if(data===true)
              fetchBodegaByArticulo(formArticulos.art_id);
            else
              setError("ERROR "+data);
          });
        } else {
          setDataArticuloBodega([
            ...dataArticuloBodega,
            ...[
              {
                artb_bodega: selectedOptions.value,
                bod_nombre: selectedOptions.label,
              },
            ],
          ]);
        }
      }
    }
    if (selectedOptions.objName === "Proveedor") {
      setFormDropDown({
        ...formDropDown,
        prov_id: selectedOptions.value,
        prov_nombre: selectedOptions.label,
      });
    }
    if (selectedOptions.objName === "Categoria") {
      setFormDropDown({
        ...formDropDown,
        cat_id: selectedOptions.value,
        cat_descripcion: selectedOptions.label,
      });
    }
    if (selectedOptions.objName === "Marca") {
      setFormDropDown({
        ...formDropDown,
        mar_id: selectedOptions.value,
        mar_descripcion: selectedOptions.label,
      });
    }
    if (selectedOptions.objName === "itbms_venta") {
      setFormDropDown({
        ...formDropDown,
        itbm_cod_venta: selectedOptions.value,
        itbm_porc_v: selectedOptions.itbm_porc_v,
        itbm_descripcion_venta: selectedOptions.label,
      });
    }
    if (selectedOptions.objName === "itbms_compra") {
      setFormDropDown({
        ...formDropDown,
        itbm_cod_compra: selectedOptions.value,
        itbm_porc_c: selectedOptions.itbm_porc_c,
        itbm_descripcion_compra: selectedOptions.label,
      });
    }
  };
  const handleOnChangeCheckBox = (event) => {
    const { value, name } = event.target;
    setFormArticulos({
      ...formArticulos,
      [name]:value==="0"?"1":"0"
  })
  };
  return (
    <Container className="bg-light border" fluid>
      {basicModal && (
        <ArticuloModal
          props={formArticulos}
          propsArtBodega={dataArticuloBodega}
          propsDropdown={propsDropdown}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveArticulos}
          toggleSaveEdit={onClickUpdateArticulos}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          onChangeImage={onChangeImage}
          images={images}
          error={error}
          onRemoveBodega={onRemoveBodega}
          removeImage={removeImage}
          handleOnChangeCheckBox={handleOnChangeCheckBox}
          loading={loading}
        />
      )}
      {basicModalDelete && (
        <DeleteModal
          props={formArticulos.art_descripcion}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateArticulo_sts}
          text={"Eliminar este registro?"}
          error={""}
        />
      )}
      {basicModalImageTooltip && (
          <ImageTooltipModal
            props={formArticulos}
            isOpen={basicModalImageTooltip}
            toggle={setModalImageTooltip}
            srcImage ={srcImage}
            ImgName ={ImgName}
          />
      )}
      {printToolti && (<Row className="barcode-box2">
          <Col  xs={3}>
            <IconButton onClick={() => {setPrintTooltip(false)}}>
              <ClearIcon />
            </IconButton>
          </Col>
          <Col xs={9} className="d-flex mb-5">
            <ComponentToPrintsBarcode
              ref={el => (componentRef = el)}
              formArticuloss={formArticulos}/>
            <ReactToPrint
              trigger={() => <div><a href="#"><IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a> </div> }
              content={() => componentRef}/>
          </Col>
        </Row>)}

      <Row className="mb-4 text-center">
        <Col xs={11}>
          <Label className="font-weight-bold-title">ARTÍCULOS</Label>
        </Col>
        <Col xs={1} className="text-end">
        <NavLink tag={RRNavLink} to="/user/home" className="font-w-bold   menu-l-c">
                  <img src={CLOSE_IMAGE} className="src-image" alt="..."/>
                </NavLink>
        </Col>
      </Row>
      <Row>
        <Col className="modal-add-inventario datatable-factura">
          <DATATABLE_ARTICULO
          dataArticulos ={dataArticulos} setBasicModalDelete  ={setBasicModalDelete} setBasicModalEdit  ={setBasicModalEdit} setBasicModal  ={openModalArticulos}setFormArticulos  ={setFormArticulos}
          setImages ={setImages} fetchArticulos ={fetchArticulos} setFormDropDown ={setFormDropDown} fetchBodegaByArticulo ={fetchBodegaByArticulo}
          setDataArticuloBodega={setDataArticuloBodega} setError={setError} setSrcImage={setSrcImage} setModalImageTooltip={setModalImageTooltip} 
          setImgName={setImgName} setPrintTooltip={setPrintTooltip} handleExportData={handleExportCsv}/>
        </Col>
      </Row>
    </Container>
  );
}