import React, { useState, useEffect } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "../../Componentes/www/style.css";
import { get_ord_cocina } from "../Services";
import { Container, Row, Col, Label } from "reactstrap";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle } from "mdb-react-ui-kit";
import { LOGO } from "../Image";

export const Cocina = () => {
  const [dataCocinas, setDataCocina] = useState([]);
  const [dataMesas, setDataMesas] = useState([]);
  async function fetchOrdenes() {
    var response = await get_ord_cocina();
    setDataCocina(response);
    var mesas = [];
    var hashMesas = {};
    mesas = response.filter((o) =>
      hashMesas[o.ord_mesa] || o.ord_mesa === null
        ? false
        : (hashMesas[o.ord_mesa] = true)
    );
    setDataMesas(mesas);
    setTimeout(() => {
      fetchOrdenes();
    }, 10000);
  }

  useEffect(() => {
    fetchOrdenes();
  }, []);

  return (
    <Container fluid>
      <Row className="pdd-1 card-cocina">
        <img src={LOGO} className="img-cocina" alt="..." />
        {dataMesas.map((data, i) => (
          <Col xs={2} className="mb-1 mt-1 padd-2p">
            <MDBCard  className="mb-3 bg-card-cocina">
              <MDBCardHeader>{data.res_nombre}</MDBCardHeader>
              <MDBCardBody>
                {dataCocinas.map((dataC) => {
                  if (dataC.ord_mesa === data.ord_mesa) {
                    return (
                      <>
                        <MDBCardTitle>
                          {Number(dataC.ordd_cantidad).toFixed(0)} - {dataC.ordd_art_descripcion}
                        </MDBCardTitle>
                      </>
                    );
                  }
                })}
              </MDBCardBody>
            </MDBCard>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
