import { reactLocalStorage } from "reactjs-localstorage";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import moment from "moment";
import {LOGO} from "./Image";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { Col,Button } from "reactstrap";
import {MDBInput} from "mdb-react-ui-kit";
import { nonce, path, privateKey,post_dc_compra_detalle_row,put_dcd_compra_detalle_row,post_ord_orden,put_facturado_orden,
  post_red_recuento_detalle,put_red_recuento_detalle_row,post_facturaElectronica,post_fac_factura,postAnular,post_AnulacionElectronica,put_cli_cliente, 
  CODIGO_SUCURSAL_EMISOR,
  MODULO_RESTAURANTE} from "./Services";

const validateTokenStorage = () => {
    var x = reactLocalStorage.getObject("tokenP");
    const hashDigest = sha256(
      nonce + x.usr_type + x.usr_id + x.usr_nombre
    );
    const hmacDigest = Base64.stringify(
      hmacSHA512(path + hashDigest, privateKey)
    );
    if (x.key !== undefined) {
      if (x.key !== hmacDigest) {
        return true;
      }
    }
    return false;
  };

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: "base_de_datos_articulos"+moment(new Date()).format("_YYYY_MM_DD")+".csv",
  });

  const csvConfigCredito = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: "reporte_credito"+moment(new Date()).format("_YYYY_MM_DD")+".csv",
  });
  const csvConfigPago = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: "reporte_pago_credito"+moment(new Date()).format("_YYYY_MM_DD")+".csv",
  });

  const getKeyToke = ({usr_type,usr_id,usr_nombre})=>{
    const hashKey = sha256(nonce + usr_type+usr_id+usr_nombre);
    const hmacKey = Base64.stringify(hmacSHA512(path + hashKey, privateKey));
    return hmacKey
  }

  const FIND_DESC = (arr, elm,elm_id) => {
    const result = arr.find(function (item) {
      if (item.cli_ruc !== undefined) {
        if (item.cli_ruc.length>0) {
          if(elm_id===null){
            if (item.cli_ruc.toLowerCase().replace(/\s+/g, "") === elm.toLowerCase().replace(/\s+/g, ""))
            return true;
          }
          else{
            if (item.cli_ruc.toLowerCase().replace(/\s+/g, "") === elm.toLowerCase().replace(/\s+/g, "") && item.cli_id !== elm_id)
            return true;
          }
        }
      }
      if (item.prov_ruc !== undefined) {
        if (item.prov_ruc.length>0) {
          if(elm_id===null){
            if (item.prov_ruc.toLowerCase().replace(/\s+/g, "") === elm.toLowerCase().replace(/\s+/g, ""))
            return true;
          }
          else{
            if (item.prov_ruc.toLowerCase().replace(/\s+/g, "") === elm.toLowerCase().replace(/\s+/g, "") && item.prov_id !== elm_id)
            return true;
          }

        }
      }
      if (item.art_upc !== undefined) {
        if (item.art_upc.length>0) {
          if(elm_id===null){
            if (item.art_upc.toLowerCase().replace(/\s+/g, "") === elm.toLowerCase().replace(/\s+/g, ""))
            return true;
          }
          else{
            if (item.art_upc.toLowerCase().replace(/\s+/g, "") === elm.toLowerCase().replace(/\s+/g, "") && item.art_id !== elm_id)
            return true;
          }

        }
      }
    });
    if (result !== undefined) return true;
    else return false;
  };

  function roundNumber(num, scale) {
    if(!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale)  + "e-" + scale);
    } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if(+arr[1] + scale > 0) {
        sig = "+";
      }
      return + (Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
  }

  function BODY_DC_COMPRA({dataCompraDetalle,formCompra,formDropDownProveedor}) {
    var totalPrecioNeto=0;
    var totalITBMS = 0;
    var dataDC_detail = dataCompraDetalle.map((val, i) => {
      totalPrecioNeto = Number(totalPrecioNeto) + (Number(val.dcd_cantidad) * Number(val.dcd_precio));//Suma de todas las ocurrencias de PrecioItem.
      totalITBMS = Number(totalITBMS) + Number(val.dcd_itbms);//Suma de todas las ocurrencias de ValorITBMS.
      return {
        dcd_identificador: formCompra.dc_identificador,
        dcd_articulo: val.dcd_articulo,
        dcd_articulo_desc:val.art_descripcion,	
        dcd_precio: val.dcd_precio,
        dcd_cantidad: val.dcd_cantidad,
        dcd_itbms: val.dcd_itbms,
        dcd_descuento: val.dcd_descuento,
        dcd_total:val.dcd_total,
        dcd_descuento_number: val.dcd_descuento,
        dcd_unique:formCompra.dc_identificador+"_"+val.dcd_articulo+"_"+i 
      };
    });
    var body = {
      formCompra: {
        dc_docn: formCompra.dc_docn,
        dc_identificador: formCompra.dc_identificador,
        dc_proveedor: formDropDownProveedor.prov_id,
        dc_fecha: formCompra.dc_fecha,
        dc_descripcion: formCompra.dc_descripcion,
        dc_itbms:totalITBMS.toFixed(4),
        dc_total:(Number(totalPrecioNeto)+Number(totalITBMS)).toFixed(2),
        dc_unique:formCompra.dc_identificador,
      },
      dataCompraDetalle: dataDC_detail,
    };
    return body;
  }

  function DC_UPDATE_CANTIDAD({dataCompraDetalle,formCompraDetalle,formCompra}){
    var flag = false;
    var itbms=0;
    var cantidad =0;
    var total =0;
    var objItems = dataCompraDetalle.filter((data, i) => {
      if (data.dcd_articulo === formCompraDetalle.dcd_articulo && data.art_descripcion === formCompraDetalle.art_descripcion) {
        flag = true;
        cantidad = (Number(data.dcd_cantidad) + Number(formCompraDetalle.dcd_cantidad)).toFixed(2);
        itbms = ((Number(cantidad)*Number(data.dcd_precio))*Number(data.art_itbm_compra)).toFixed(2);
        data.dcd_cantidad = Number(cantidad).toFixed(2);
        data.dcd_itbms = itbms;
        data.dcd_total = ((Number(cantidad)*Number(formCompraDetalle.dcd_precio))+(Number(itbms))).toFixed(2);

        return data;
      }});
    if (!flag) {
      itbms = ((Number(formCompraDetalle.dcd_cantidad)*Number(formCompraDetalle.dcd_precio))*Number(formCompraDetalle.art_itbm_compra)).toFixed(2);
      total = (Number(formCompraDetalle.dcd_cantidad)*Number(formCompraDetalle.dcd_precio))+Number(itbms);
      var bodyInsertRow={
        dcd_identificador:formCompra.dc_identificador,
        dcd_articulo:formCompraDetalle.dcd_articulo,	
        dcd_precio:formCompraDetalle.dcd_precio,	
        dcd_articulo_desc:formCompraDetalle.art_descripcion,	
        dcd_cantidad:	formCompraDetalle.dcd_cantidad,
        dcd_itbms:itbms,	
        dcd_total:total.toFixed(2),	
        dcd_unique:formCompra.dc_identificador+"_"+formCompraDetalle.dcd_articulo+"_"+0
      }
     
      post_dc_compra_detalle_row(bodyInsertRow)
    } else {
      var bodyInsertRow={
        dcd_id:objItems[0].dcd_id,
        dcd_identificador:formCompra.dc_identificador,
        dcd_articulo:formCompraDetalle.dcd_articulo,	
        dcd_articulo_desc:formCompraDetalle.art_descripcion,	
        dcd_precio:formCompraDetalle.dcd_precio,	
        dcd_cantidad: Number(objItems[0].dcd_cantidad),
        dcd_itbms:Number(objItems[0].dcd_itbms),	
        dcd_total:Number(objItems[0].dcd_total),	
      }
      put_dcd_compra_detalle_row(bodyInsertRow)
    }
  }
  function BODY_RE_RECUENTO({dataRecuentoDetalle,formRecuento}) {
    var dataRed_Recuento_detalle = dataRecuentoDetalle.map((val, i) => {
      return {
        red_identificador: formRecuento.re_identificador,
        red_articulo: val.red_articulo,
        red_cantidad:val.red_cantidad,	
        red_unique:formRecuento.re_identificador+"_"+val.red_articulo+"_"+i 
      };
    });

    var body = {
      formRecuento: {
        re_identificador: formRecuento.re_identificador,
        re_descripcion: formRecuento.re_descripcion,
        re_unique:formRecuento.re_identificador,
      },
      dataRecuentoDetalle: dataRed_Recuento_detalle,
    };
    return body;
  }

  function RE_UPDATE_CANTIDAD({dataRecuentoDetalle,formRecuentoDetalle,formRecuento}){
    var flag = false;
    var cantidad =0;
    var objItems = dataRecuentoDetalle.filter((data, i) => {
      if (data.red_articulo === formRecuentoDetalle.red_articulo && data.art_descripcion === formRecuentoDetalle.art_descripcion) {
        flag = true;
        cantidad = Number(data.red_cantidad) + Number(formRecuentoDetalle.red_cantidad);
        data.red_cantidad = Number(cantidad).toFixed(2);
        return data;
      }});
    if (!flag) {
      var body={
        red_identificador:formRecuento.re_identificador,
        red_articulo:formRecuentoDetalle.red_articulo,
        red_cantidad:formRecuentoDetalle.red_cantidad,
        red_unique:formRecuento.re_identificador+"_"+formRecuentoDetalle.red_articulo+"_"+0
      }
      post_red_recuento_detalle(body)
    } else {
      var body={
        red_id:objItems[0].red_id,
        red_identificador:formRecuentoDetalle.red_identificador,
        red_articulo:formRecuentoDetalle.red_articulo,	
        red_cantidad: Number(objItems[0].red_cantidad),
      }
      put_red_recuento_detalle_row(body)
    }
  }
  function formatAMPM(date) {
    var dates = moment(date).format("YYYY-MM-DD");
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return dates + " " +strTime;
  }

  function SET_DATA_COMPANY({data}){
    return {
      com_ruc:data.com_ruc +" DV " +data.com_dv,
      com_nombre:data.com_nombre,
      com_direccion:data.com_direccion,
      com_telefono:data.com_telefono,
      com_telefono2:data.com_telefono2,
      com_email:data.com_email,
      com_web:data.com_web,
    }
  }

  function COPIA_FACTURA({formGarantia,dataFac,dataFpago,dataCompania,dataSelected,usr_prints}){
    var ObjFPago = [];
    var ObjItems = [];
    var totalValorRecibido=0;
    var totalPrecioNeto=0;
    var totalITBMS = 0;
    var nroItems=0;
    var vueltoR=0;
    var montoEntregado =0;
    var dataCompany = SET_DATA_COMPANY({data:dataCompania})
    dataFac.map((data)=>{
      totalPrecioNeto = Number(totalPrecioNeto) + (Number(data.fcd_cantidad) * Number(data.fcd_precio));//Suma de todas las ocurrencias de PrecioItem.
      totalITBMS = Number(totalITBMS) + Number(data.fcd_itbms);//Suma de todas las ocurrencias de ValorITBMS.
      totalValorRecibido = Number(data.fcd_entregado);
      nroItems = nroItems + 1;
      ObjItems.push({
        descripcion: data.fcd_art_descripcion,
        codigo: data.art_upc,
        cantidad: Number(data.fcd_cantidad).toFixed(2),
        precioUnitario: Number(data.fcd_precio).toFixed(2),
        precioUnitarioDescuento:"0.00",
        precioItem:(Number(data.fcd_cantidad) * Number(data.fcd_precio)).toFixed(2),//PrecioItem <> Cantidad * (PrecioUnitario - PrecioUnitarioDescuento)
        valorTotal: ((Number(data.fcd_cantidad) * Number(data.fcd_precio)) + (Number(data.fcd_itbms))).toFixed(3),//Suma del precio del ítem con los montos de los impuestos
        tasaITBMS: data.fcd_itbms_code,//Tasa del ITBMS aplicable al ítem. 00:0% (exento) 01:7% 02:10% 03:15%
        valorITBMS: Number(data.fcd_itbms).toFixed(3),//Monto del ITBMS del ítem. Producto de TasaITBMS * PrecioItem
      });
    });

    dataFpago.map((data,i)=>{
      ObjFPago.push({
        formaPagoFact:data.fcfp_number,
        descFormaPago:data.fcfp_number==="99"?data.fcfp_fpago:null,
        valorCuotaPagada:Number(data.fcfp_monto).toFixed(2),
        descFormaPagoR:data.fcfp_fpago
      });
      montoEntregado = montoEntregado + Number(data.list_monto);
    });

    vueltoR = (Number(totalValorRecibido) - ((Number(totalPrecioNeto) + Number(totalITBMS))));
    var bodyFactura = {
      prefactura:false,
      fac_anulacion:false,
      codigoSucursalEmisor:CODIGO_SUCURSAL_EMISOR,
      motivo_anulacion:"",
      fac_docn:dataSelected.fac_id.toString().padStart(10, "0"),
      fac_fecha:dataSelected.fac_fecha,
      cli_email:dataSelected.cli_email.length>12?dataSelected.cli_email:null,
      cli_ruc:dataSelected.cli_ruc,
      cli_name:dataSelected.cli_nombre,
      cli_dv:dataSelected.cli_dv,
      listaItems:ObjItems,
      totalPrecioNeto:Number(totalPrecioNeto).toFixed(2),
      totalITBMS:Number(totalITBMS).toFixed(2),
      totalMontoGravado:Number(totalITBMS).toFixed(2),
      totalFactura:(Number(totalPrecioNeto) + Number(totalITBMS)).toFixed(2),////Suma de TotalTodosItems, TotalAcarreoCobrado, ValorSeguroCobrado menos TotalDescuento.
      totalTodosItems:(Number(totalPrecioNeto) + Number(totalITBMS)).toFixed(2),
      nroItems,
      valorCuotaPagada:(Number(totalPrecioNeto)+Number(totalITBMS)).toFixed(2),
      totalValorRecibido:totalValorRecibido.toFixed(2),//(Number(totalPrecioNeto) + Number(totalITBMS)).toFixed(2),
      vuelto: Number(vueltoR)<=0?"0.00":vueltoR.toFixed(2),//"0.00",
      print_type:usr_prints, //1. pdf, 2.recibo 3. recibo no fiscal
      informacionInteres: formGarantia.gr_descripcion+" \n"+ dataCompania.com_info_interes,
      listaFormaPago:ObjFPago,
      dataCompany,
      props_anulacion:[],
      facCopia:true,
    }
    post_facturaElectronica(bodyFactura);

  }

  function SAVE_ORDEN({dataFac,formFactura,formRestaurante}){
    var totalPrecioNeto=0;
    var totalITBMS = 0;

    var dataOrden_detail = dataFac.map((data,i)=>{
      totalPrecioNeto = Number(totalPrecioNeto) + (Number(data.ordd_cantidad) * Number(data.ordd_precio));//Suma de todas las ocurrencias de PrecioItem.
      totalITBMS = Number(totalITBMS) + roundNumber(Number(data.ordd_itbms),3);//Suma de todas las ocurrencias de ValorITBMS.
      return {
        ordd_identificador: formFactura.fac_identificador,
        ordd_articulo: data.ordd_articulo,
        ordd_precio: data.ordd_precio,
        ordd_cantidad: data.ordd_cantidad,
        ordd_art_descripcion: data.art_descripcion,
        ordd_itbms_code: data.art_itbm_cod_v,
        ordd_itbms: roundNumber(data.ordd_itbms,2),
        ordd_descuento: data.ordd_descuento,
        ordd_total:roundNumber((Number(data.ordd_cantidad) * Number(data.ordd_precio))+(Number(data.ordd_itbms)),2) ,
        ordd_descuento_number: data.ordd_descuento,
        ordd_unique:formFactura.fac_identificador+"_"+data.ordd_articulo+"_"+i
      };
    });

    var body = { 
      formOrden: {
        ord_identificador: formFactura.fac_identificador,
        ord_mesa: formRestaurante.res_mesa_id,
        ord_vendedor:formFactura.fac_vendedor,
        ord_fecha:formFactura.fac_fecha,
        ord_unique:formFactura.fac_identificador,
      },
      dataOrdenDetalle: dataOrden_detail,
    };
  
    post_ord_orden(body);
  }

  function SAVE_FACTURA({formGarantia,formRestaurante,dataFac,dataFpago,prefactura,dataCompania,formFactura,formDropDownFPago,formClientes,usr_prints}){
    var ObjItems = [];
    var ObjFPago = [];
    var Objfactura_fpago = [];
    var totalValorRecibido=0;
    var totalPrecioNeto=0;
    var totalITBMS = 0;
    var totaldescuento = 0;
    var nroItems=0;
    var vueltoR=0;
    var montoEntregado =0;
    var montoCredito = 0;
    var isCreditV=false;
    var dataCompany = SET_DATA_COMPANY({data:dataCompania})
    dataFpago.map((data,i)=>{
      Objfactura_fpago.push({
        fcfp_identificador:formFactura.fac_identificador,
        fcfp_number:data.list_fpago,
        fcfp_fpago:data.list_fpagoName,
        fcfp_monto:data.list_monto,
        fcfp_unique:formFactura.fac_identificador+data.list_fpago
      });

      ObjFPago.push({
        formaPagoFact:data.list_fpago,
        descFormaPago:data.list_fpago==="99"?data.list_fpagoName:null,
        valorCuotaPagada:Number(data.list_monto).toFixed(2),
        descFormaPagoR:data.list_fpagoName
      })
      montoEntregado = montoEntregado + Number(data.list_monto);
      totalValorRecibido = totalValorRecibido + Number(data.list_monto);
      if(data.list_fpago==="01"){
        montoCredito=data.list_monto;
        isCreditV= true;
      }
    });

    var dataFAC_detail = dataFac.map((data,i)=>{
      console.log(data)
      totalPrecioNeto = Number(totalPrecioNeto) + (Number(data.fcd_cantidad) * Number(data.fcd_precio));//Suma de todas las ocurrencias de PrecioItem.
      totalITBMS = Number(totalITBMS) + roundNumber(Number(data.fcd_itbms),3);//Suma de todas las ocurrencias de ValorITBMS.
      totaldescuento = Number(totaldescuento) + roundNumber(Number(data.fcd_descuento),3);
      nroItems = nroItems + 1;
      ObjItems.push({
        descripcion: data.art_descripcion,
        codigo: data.art_upc,
        cantidad: Number(data.fcd_cantidad).toFixed(2),
        precioUnitario: Number(data.fcd_precio).toFixed(2),
        precioUnitarioDescuento:Number(data.fcd_descuento).toFixed(2),
        precioItem:((Number(data.fcd_cantidad) * Number(data.fcd_precio))-Number(data.fcd_descuento)).toFixed(2),//PrecioItem <> Cantidad * (PrecioUnitario - PrecioUnitarioDescuento)
        valorTotal:Number(Number(data.fcd_total).toFixed(2)),
        // valorTotal: (((Number(data.fcd_cantidad) * Number(data.fcd_precio))-Number(data.fcd_descuento)) + (roundNumber(Number(data.fcd_itbms),3))).toFixed(3),//Suma del precio del ítem con los montos de los impuestos
        tasaITBMS: data.art_itbm_cod_v,//Tasa del ITBMS aplicable al ítem. 00:0% (exento) 01:7% 02:10% 03:15%
        valorITBMS: Number(roundNumber(data.fcd_itbms,3)).toFixed(3),//Monto del ITBMS del ítem. Producto de TasaITBMS * PrecioItem
      });

      return {
        fcd_identificador: formFactura.fac_identificador,
        fcd_articulo: data.fcd_articulo,
        fcd_precio: data.fcd_precio,
        fcd_cantidad: data.fcd_cantidad,
        fcd_art_descripcion: data.art_descripcion,
        fcd_itbms_code: data.art_itbm_cod_v,
        fcd_itbms: roundNumber(data.fcd_itbms,2),
        fcd_descuento: data.fcd_descuento,
        fcd_total:roundNumber(Number(data.fcd_total),2),
        // fcd_total:roundNumber(((Number(data.fcd_cantidad) * Number(data.fcd_precio))-Number(data.fcd_descuento))+(Number(data.fcd_itbms)),2) ,
        fcd_entregado:montoEntregado,
        fcd_formapago:formDropDownFPago.frm_descripcion,
        fcd_descuento_number: data.fcd_descuento_number,
        fcd_unique:formFactura.fac_identificador+"_"+data.fcd_articulo+"_"+i
      };
    });

    vueltoR = (Number(totalValorRecibido) - ((Number(totalPrecioNeto) - Number(totaldescuento)+ roundNumber(Number(totalITBMS),2))));
    var Objfactura_fpagoR=  Objfactura_fpago.map((datar)=>{
        if(datar.fcfp_number ==="02"){
          return {...datar,
            fcfp_monto:(Number(datar.fcfp_monto) - Number(vueltoR)).toFixed(2)
          }
        }
        return datar
      });

    var body = { 
      formFactura: {
        fac_identificador: formFactura.fac_identificador,
        fac_cliente: formClientes.cli_id,
        fac_vendedor:formFactura.fac_vendedor,
        fac_fecha:formFactura.fac_fecha,
        fac_unique:formFactura.fac_identificador,
        fac_itbms:roundNumber(totalITBMS,2),
        fac_total:roundNumber(Number(totalPrecioNeto)-Number(totaldescuento)+Number(totalITBMS),2),
        fac_recibo:usr_prints ==="3"?"0":"1",
      },
      dataFacturaDetalle: dataFAC_detail,
      fcfp_factura_fpago:Objfactura_fpagoR,
      isCredit:isCreditV,
      cr_credito:{
        cr_cliente:formClientes.cli_id,	
      },
      crd_credito_detalle:{
       crd_cliente:formClientes.cli_id,	crd_fac_identificador:formFactura.fac_identificador,	crd_monto:montoCredito,	crd_fecha:formFactura.fac_fecha
      }

    };
    if(prefactura){
      var bodyFactura = {
        codigoSucursalEmisor:CODIGO_SUCURSAL_EMISOR,
        prefactura,
        fac_anulacion:false,
        motivo_anulacion:"",
        fac_docn:"Prefactura",
        fac_fecha:formFactura.fac_fecha,
        cli_email:formClientes.cli_email.length>12?formClientes.cli_email:null,
        cli_ruc:formClientes.cli_ruc,
        cli_name:formClientes.cli_nombre,
        cli_dv:formClientes.cli_dv,
        frm_code:formDropDownFPago.frm_code,
        frm_descripcion:formDropDownFPago.frm_descripcion,
        listaItems:ObjItems,
        totalPrecioNeto:Number(totalPrecioNeto).toFixed(2),
        totalITBMS:roundNumber(Number(totalITBMS),2).toFixed(2),
        totalMontoGravado:Number(totalITBMS).toFixed(2),
        totalFactura:(Number(totalPrecioNeto) + roundNumber(Number(totalITBMS),2)).toFixed(2),////Suma de TotalTodosItems, TotalAcarreoCobrado, ValorSeguroCobrado menos TotalDescuento.
        totalTodosItems:(Number(totalPrecioNeto) + Number(totalITBMS)).toFixed(2),
        nroItems,
        valorCuotaPagada:(Number(totalPrecioNeto)+Number(totalITBMS)).toFixed(2),
        totalValorRecibido:totalValorRecibido.toFixed(2),//(Number(totalPrecioNeto) + Number(totalITBMS)).toFixed(2),
        vuelto: Number(vueltoR)<=0?"0.00":vueltoR.toFixed(2),//"0.00",
        print_type:usr_prints, //1. pdf, 2.recibo 3. recibo no fiscal
        informacionInteres: formGarantia.gr_descripcion+" \n"+ dataCompania.com_info_interes,
        listaFormaPago:ObjFPago,
        dataCompany,
        props_anulacion:[],
        facCopia:false,
      }
      post_facturaElectronica(bodyFactura)
    }
    else{
      var result =  post_fac_factura(body);
      result.then((data) => {
        if(data !== false){
          put_facturado_orden({ordd_identificador:formRestaurante.current_ord_identificador})
          var bodyFactura = {
            codigoSucursalEmisor:CODIGO_SUCURSAL_EMISOR,
            prefactura,
            fac_anulacion:false,
            motivo_anulacion:"",
            fac_docn:data.toString().padStart(10, "0"),
            fac_fecha:formFactura.fac_fecha,
            cli_email:formClientes.cli_email.length>12?formClientes.cli_email:null,
            cli_ruc:formClientes.cli_ruc,
            cli_name:formClientes.cli_nombre,
            cli_dv:formClientes.cli_dv,
            frm_code:formDropDownFPago.frm_code,
            frm_descripcion:formDropDownFPago.frm_descripcion,
            listaItems:ObjItems,
            totaldescuento:Number(totaldescuento).toFixed(2),
            totalPrecioNeto:Number(totalPrecioNeto-totaldescuento).toFixed(2),
            totalITBMS:roundNumber(Number(totalITBMS),2).toFixed(2),
            totalMontoGravado:Number(totalITBMS).toFixed(2),
            totalFactura:(Number(totalPrecioNeto)-Number(totaldescuento) + roundNumber(Number(totalITBMS),2)).toFixed(2),////Suma de TotalTodosItems, TotalAcarreoCobrado, ValorSeguroCobrado menos TotalDescuento.
            totalTodosItems:(Number(totalPrecioNeto) -Number(totaldescuento) + Number(totalITBMS)).toFixed(2),
            nroItems,
            valorCuotaPagada:(Number(totalPrecioNeto)+Number(totalITBMS)).toFixed(2),
            totalValorRecibido:totalValorRecibido.toFixed(2),//(Number(totalPrecioNeto) + Number(totalITBMS)).toFixed(2),
            vuelto:  Number(vueltoR)<=0?"0.00":vueltoR.toFixed(2),//"0.00",
            print_type:usr_prints, //1. pdf, 2.recibo 3. recibo no fiscal
            informacionInteres: formGarantia.gr_descripcion+" \n"+ dataCompania.com_info_interes,
            listaFormaPago:ObjFPago,
            dataCompany,
            props_anulacion:[],
            facCopia:false,
          }
          post_facturaElectronica(bodyFactura)
        }
        // else{
        //   postError(JSON.stringify(body))
        //   setError("No puedo guardar la factura");
        // }
        return data;
      })
    }
    }

  function ANULAR_FACTURA({formGarantia,dataCompania,formAnulacion,formClientes,usr_prints,formDropDownFPago}){
    var dataCompany = SET_DATA_COMPANY({data:dataCompania})
    var body = {
      prefactura:false,
      fac_anulacion:true,
      fac_fecha:formAnulacion.fac_fecha,
      codigoSucursalEmisor:CODIGO_SUCURSAL_EMISOR,
      motivo_anulacion:formAnulacion.motivo_anulacion,
      fac_docn: formAnulacion.fac_id.toString().padStart(10, "0"),
      props_anulacion:{...formAnulacion,fac_date_anulacion:moment(new Date()).format("YYYY-MM-DD HH:mm:ss")},
      cli_email:null,
      cli_ruc:formClientes.cli_ruc,
      cli_name:formClientes.cli_nombre,
      cli_dv:formClientes.cli_dv,
      frm_code:formDropDownFPago.frm_code,
      frm_descripcion:formDropDownFPago.frm_descripcion,
      listaItems:[],
      totalPrecioNeto:null,
      totalITBMS:null,
      totalMontoGravado:null,
      totalFactura:null,
      totalTodosItems:null,
      nroItems:null,
      valorCuotaPagada:null,
      totalValorRecibido:null,
      vuelto: null,//"0.00",
      print_type:usr_prints, //1. pdf, 2.recibo 3. recibo no fiscal
      informacionInteres: formGarantia.gr_descripcion+" \n"+ dataCompania.com_info_interes,
      listaFormaPago:[],
      dataCompany,
      facCopia:false,
    };
    
    if(formAnulacion.motivo_anulacion.length>0){
      postAnular(body);
      post_AnulacionElectronica(body)
      return true
    }
    else{
     return false
    }
  }

  function PUT_CLIENTE({formClientes}){
    var body = {
      cli_id: formClientes.cli_id,
      cli_nombre: formClientes.cli_nombre,
      cli_direccion: formClientes.cli_direccion,
      cli_telefono: formClientes.cli_telefono,
      cli_telefono2: formClientes.cli_telefono2,
      cli_ruc: formClientes.cli_ruc,
      cli_dv: formClientes.cli_dv,
      cli_email: formClientes.cli_email,
      cli_limitc: formClientes.cli_limitc,
    };
    put_cli_cliente(body);
  }
  
   function FOOTER_FACTURA({enableTab,subtotal,itbms,total,usr_perfil}){///usr_perfil 3. tablet
    return <>
    <Col xs={usr_perfil==="3"?3:6}>
    </Col>
    <Col xs={usr_perfil==="3"?3:2}>
      <MDBInput
        className="input-iventario-totales"
        id="fcd_i_subtotal"
        wrapperClass="mb-2"
        label="SUBTOTAL"
        name="fcd_i_subtotal"
        value={enableTab ? [] : Number(subtotal).toFixed(2)}
        disabled={true}
      />
    </Col>
    <Col xs={usr_perfil==="3"?3:2}>
      <MDBInput
        className="input-iventario-totales"
        id="fcd_i_itbms"
        wrapperClass="mb-2"
        label="ITBMS"
        name="fcd_i_itbms"
        value={enableTab ? [] : Number(itbms).toFixed(2)}
        disabled={true}
      />
    </Col>
    <Col xs={usr_perfil==="3"?3:2}>
      <MDBInput
        className="input-iventario-totales"
        id="fcd_i_total"
        wrapperClass="mb-2"
        label="TOTAL"
        name="fcd_i_total"
        value={enableTab ? [] : Number(total).toFixed(2)}
        disabled={true}
      />
    </Col>
  </>
   }

   
  const EXPORT_CSV_ARTICULOS = ({dataArticulos}) => {
    var art= dataArticulos.map((data,i)=>{
      return{
        ID:data.art_id,
        Codigo: data.art_upc,
        Descripción: data.art_descripcion,
        Cantidad: data.inv_qty,
        Costo: data.art_costo,
        Precio: data.art_precio,
        Precio2: data.art_precio2,
        Precio3: data.art_precio3,
        Categoria: data.cat_descripcion,
        Proveedor: data.prov_nombre,
        Marca:data.mar_descripcion,
        Bodega:data.bod_nombre,
        Referencia: data.art_referencia,
        Serie:data.art_serie,
        Linea_roja: data.art_linea_roja,
        Itbm_venta: data.art_itbm_venta,
        Itbm_compra: data.art_itbm_compra,
      }
    })

    const csv = generateCsv(csvConfig)(art);
    download(csvConfig)(csv);
  };

  const EXPORT_CSV_CREDITOS = ({dataCreditos}) => {
    var credito= dataCreditos.map((data,i)=>{
      return{
        ID:data.fac_id.toString().padStart(10, "0"),
        Cliente: data.cli_nombre,
        RUC: data.cli_ruc,
        Monto: data.crd_monto,
        Fecha: data.crd_fecha,
      }
    })

    const csv = generateCsv(csvConfigCredito)(credito);
    download(csvConfigCredito)(csv);
  };

  const EXPORT_CSV_CREDITOS_PAGO = ({dataCreditospago}) => {
    var pago= dataCreditospago.map((data,i)=>{
      return{
        ID:data.fac_id.toString().padStart(10, "0"),
        Cliente: data.cli_nombre,
        RUC: data.cli_ruc,
        Monto: data.crp_monto,
        Fecha: data.crp_fecha,
        Eliminado: data.crp_fecha_delete,
      }
    })

    const csv = generateCsv(csvConfigPago)(pago);
    download(csvConfigPago)(csv);
  };

  const FACTURA_ACTION_BTN = ({fetchReportX,setModalAnular,fetchListAnulacion,setErrorAnulacion,setModalCopiaF,fetchListFacImprimir,fetchCotizacionList,
    setModalCreditos,fetchCrCredito,fetchOrdenesList}) => {
    return<>
      <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>fetchReportX()}>Informe X</Button>
      <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{setModalAnular(true); fetchListAnulacion(); setErrorAnulacion("")}}>Anulación</Button>
      <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{setModalCopiaF(true);fetchListFacImprimir()}}>Copia</Button>
      <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{fetchCotizacionList()}}>Cotizaciones</Button>
      <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{fetchCrCredito();setModalCreditos(true)}}>Créditos</Button>
      {MODULO_RESTAURANTE?<Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{fetchOrdenesList();}} >Ordenes</Button>:null}
      {/* <Button className=" btn-reporte_x button-color-gray" variant="contained" disabled={true}>Apartados</Button> */}
      <img src={LOGO} className="logo-factura" alt="..." /></>
  };

  export {
    FACTURA_ACTION_BTN,
    EXPORT_CSV_CREDITOS_PAGO,
    EXPORT_CSV_CREDITOS,
    EXPORT_CSV_ARTICULOS,
    FOOTER_FACTURA,
    validateTokenStorage,
    getKeyToke,
    FIND_DESC,
    roundNumber,
    BODY_DC_COMPRA,
    DC_UPDATE_CANTIDAD,
    BODY_RE_RECUENTO,
    RE_UPDATE_CANTIDAD,
    formatAMPM,
    SET_DATA_COMPANY,
    COPIA_FACTURA,
    SAVE_FACTURA,
    ANULAR_FACTURA,
    PUT_CLIENTE,
    SAVE_ORDEN
  };
